import detail1 from '../../../../assets/bg_1detail.jpg'
import detail2 from '../../../../assets/bg_2detail.jpg'
import detail3 from '../../../../assets/bg_3detail.jpg'
import detail4 from '../../../../assets/bg_4detail.jpg'
import detail5 from '../../../../assets/bg_5detail.jpg'
import yuandian from '../../../../assets/圆点.png'
const ClassThree = () => {
  return (
    <div className="detail-item-1">
      <div className="bg-1">
        <img src={detail1} alt="" />
        <div className="text">
          <h2>帮助某德系品牌打造直售代理模式</h2>
        </div>
      </div>
      <div className="bg-2">
        <img src={detail2} alt="" />
        <div className="text">
          <h3>背景与目标</h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
            近年来，越来越多的汽车品牌选择代理商销售模式。由于
          </p>
          <p>经销模式转换是一个过程，从现有的经销模式向直销/代理模式</p>
          <p>的转型是一个较为长期的过程。普华永道子公司Strategy &在</p>
          <p>最近发布的一份针对汽车零售市场未来分析报告《汽车零售变</p>
          <p>革2030》中提出，直销/代理模式是汽车经销未来发展的必然</p>
          <p>选择。</p>
        </div>
      </div>
      <div className="bg-3">
        <img src={detail3} alt="" />
        <div className="text">
          <h3>痛点及挑战</h3>

          <ul>
            <li>
              <img src={yuandian} alt="" />
              全球汽车行业面临的重大挑战主要有两个：一是OEM多年来为追求高速增长而导致的产品供应过渡；
            </li>
            <li>
              <img src={yuandian} alt="" />
              二是年轻客户喜欢通过数字渠道购买汽车，并期待更灵活的所有权模式，无论是OEM还是经销商都
              <br /> 没有为此做好充分的准备。
            </li>
            <li>
              <img src={yuandian} alt="" />
              直销/代理模式转型成功将为消费者、OEM和经销商带来巨大的利益。消费者将能够享受一个无缝连
              <br /> 接、连续一致的购车旅程。
            </li>
            <li>
              <img src={yuandian} alt="" />
              OEM将受益于分销成本的大幅降低，而数字化将提高OEM对客户需求的理解，并使他们能够更专注
              <br />于 客户最重视的活动。
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-4">
        <img src={detail4} alt="" />
        <div className="text">
          <h3>解决方案</h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
            直销/代理模式是取代当前三级经销模式的最佳选择。尽管
          </p>
          <p>OEM必须为此进行中长期的转型和投资，但从长远来看该模式</p>
          <p>对OEM带来的利益优势将会大大超过初期的投入。B+b To C特</p>
          <p>色模式确保各方权益。</p>
        </div>
      </div>
      <div className="bg-5">
        <img src={detail5} alt="" />
        <div className="text">
          <h3>产品优势</h3>
          <p>
            为客户提供一个无缝和一致的客户旅程，从在线配置到亲自去经销商处进一步咨询或试驾的完整过程。
          </p>
          <ul>
            <li>
              <img src={yuandian} alt="" />
              为OEM提供一个完全数字化的客户旅程，从而让OEM提高对客户需求、汽车配置和服务预订的理解。
            </li>
            <li>
              <img src={yuandian} alt="" />
              OEM可以根据每个消费者的喜好，提供更令人满意和更个性化的产品和购物体验。
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ClassThree
