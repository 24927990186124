import detail1 from '../../../../assets/bg_1detail.jpg'
import detail2 from '../../../../assets/bg_2detail.jpg'
import detail3 from '../../../../assets/bg_3detail.jpg'
import detail4 from '../../../../assets/bg_4detail.jpg'
import detail5 from '../../../../assets/bg_5detail.jpg'
import yuandian from '../../../../assets/圆点.png'
import './class.less'
const ClassTwo = () => {
  return (
    <div className="detail-item-1 detail-item-3">
      <div className="bg-1">
        <img src={detail1} alt="" />
        <div className="text">
          <h2>豪华品牌二手车业务</h2>
        </div>
      </div>
      <div className="bg-2">
        <img src={detail2} alt="" />
        <div className="text">
          <h3>背景与目标</h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
            知名车企想要从数据层面解决企业烟囱式业务系统的数据成为
          </p>
          <p>新车销量的第一主导因素；因此，没有二手车和处置能力，会对品</p>
          <p>牌残值和新车销售造成重大不利影响。</p>
        </div>
      </div>
      <div className="bg-3">
        <img src={detail3} alt="" />
        <div className="text">
          <h3>痛点及挑战</h3>

          <ul>
            <li>
              <img src={yuandian} alt="" />
              主机厂开展二手车业务面临两大关键问题：
              对经销商二手车业务掌控能力弱和经销商意愿不高；
            </li>
            <li>
              <img src={yuandian} alt="" />
              对应八大痛点，分别是缺车源、缺政策、缺体系、缺赋能、缺盈利、缺管理、缺能力和缺客流。
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-4">
        <img src={detail4} alt="" />
        <div className="text">
          <h3>解决方案</h3>

          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
            从车源、认证、营销、零/批售、衍生支持等维度，制定品牌认证
          </p>
          <p>二手车业务的发展策略。建立牌二手车运营指导手册，全面指导经销商</p>
          <p>服务，帮助客户逐步实现数据规范化和标准化。大数据管理平台置换和</p>
          <p>认证二手车业务。对认证二手车系统进行升级优化，赋能经销商快速建</p>
          <p>立认证二手车业务能力。管理工具赋能，帮助厂家和经销商获取客户估</p>
          <p>值行为、置换线索、二手车零售线索等 网络建设优化：经销商招募和验</p>
          <p>收，累计招募 132 家，授权留存 109 家培训辅导：二手车业务课程开</p>
          <p>发及培训辅导</p>
        </div>
      </div>
      {/* <div className="bg-5">
        <img src={detail5} alt="" />
        <div className="text">
          <h3>产品优势</h3>
          <p>
            大数据管理平台能够实现数据的全生命周期管理，帮助各角色用户快速识别各业务系统的数据，并进行统一管理，最后将标
            准的、高质量、 高准确性的数据提供给业务人员和前台业务系统，
            <br />
            它具备以下优势：
          </p>
          <ul>
            <li>
              <img src={yuandian} alt="" />
              全域数据资产采集、清洗和整合
            </li>
            <li>
              <img src={yuandian} alt="" />
              实现数据从创建到消亡全生命周期的可视化管理
            </li>
            <li>
              <img src={yuandian} alt="" />
              完善的数据资产审核流程，全面保障纳入平台中数据资产的准确性、权威性
            </li>
            <li>
              <img src={yuandian} alt="" />
              数据资产管理流程可自定义，满足各类审批场景
            </li>
            <li>
              <img src={yuandian} alt="" />
              丰富的智能元素和功能，大大缩短数据管理周期、减少成本浪费
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  )
}

export default ClassTwo
