import React, { useState } from 'react'
import TopMenu from '../topMenu'
import LogoImg from '@/assets/logo.png'
import './index.less'

const CommenHeader = () => {
  const [showOpacity, setShowOpacity] = useState(false)
  const handleScroll = () => {
    if (document.documentElement.scrollTop > 70) {
      setShowOpacity(true)
    } else {
      setShowOpacity(false)
    }
  }
  window.addEventListener('scroll', handleScroll)

  return (
    <div
      className={showOpacity ? 'commen-header opacity' : 'commen-header'}
      style={{ zIndex: '9999' }}
    >
      <div className="header-logo">
        <img src={LogoImg} alt="logo"></img>
        <span>汽车全链路数智赋能者</span>
      </div>
      <div className="header-nav">
        <TopMenu></TopMenu>
      </div>
    </div>
  )
}

export default CommenHeader
