import { Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
const items = [
  {
    label: <Link to="/ecology">公司生态</Link>,
    key: '/ecology',
  },
  {
    label: <Link to="/academy">众调研究院</Link>,
    key: '/academy',
  },
  {
    label: <Link to="/case">成功案例</Link>,
    key: '/case',
  },
  {
    label: (
      <Link to="/datacenter" style={{ color: '#fff' }}>
        解决方案
      </Link>
    ),
    // label: '解决方案',
    key: 'solution',
    children: [
      {
        label: <Link to="/datacenter">数据中台</Link>,
        key: '/datacenter',
      },
      {
        label: <Link to="/AIOpenPlatform">AI中台</Link>,
        key: '/AIOpenPlatform',
      },
      {
        label: <Link to="/dealengine">成交引擎</Link>,
        key: '/dealengine',
      },
      {
        label: <Link to="/saleengine">直售引擎</Link>,
        key: '/saleengine',
      },
      {
        label: <Link to="/annotationPlatform">标注平台</Link>,
        key: '/annotationPlatform',
      },
    ],
  },
  {
    label: <Link to="/">首页</Link>,
    key: 'home',
  },
]

const TopMenu = () => {
  const currentPath = useLocation().pathname
  const [current, setCurrent] = useState(currentPath)
  useEffect(() => {
    if (currentPath == '/datacenter') {
      setCurrent('/datacenter')
    } else if (currentPath == '/') {
      setCurrent('home')
    }
  }, [currentPath])
  const onClick = (e) => {
    // console.log(e.key)
    setCurrent(e.key)
  }

  return (
    <Menu
      style={{ flex: 'auto', minWidth: '50vw' }}
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={items}
    />
  )
}

export default TopMenu
