import detail1 from '../../../../assets/bg_1detail.jpg'
import detail2 from '../../../../assets/bg_2detail.jpg'
import detail3 from '../../../../assets/bg_3detail.jpg'
import detail4 from '../../../../assets/bg_4detail.jpg'
import detail5 from '../../../../assets/bg_5detail.jpg'
import yuandian from '../../../../assets/圆点.png'
const ClassFour = () => {
  return (
    <div className="detail-item-1 detail-item-2">
      <div className="bg-1">
        <img src={detail1} alt="" />
        <div className="text">
          <h2>帮助某豪华品牌DCC项目</h2>
        </div>
      </div>
      <div className="bg-2">
        <img src={detail2} alt="" />
        <div className="text">
          <h3>背景与目标</h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
            汽车消费模式潜移默化的发生转变，“线索”成为各大品
          </p>
          <p>牌的主战场，也是经销商赖以生存的生命主线，竞争日趋白热</p>
          <p>化的今天，经销商迫于生存压力，节约人力成本，致使人员效</p>
          <p>能饱和，造成“线索”浪费，缺乏有效管理体系。如何在传统</p>
          <p>汽车消费模式下，从网电销板块寻求新生机成为新课题。</p>
        </div>
      </div>
      <div className="bg-3">
        <img src={detail3} alt="" />
        <div className="text">
          <h3>痛点及挑战</h3>

          <ul>
            <li>
              <img src={yuandian} alt="" />
              数字化转型面临两大断层，总部的运营理念和数字工具难以向下传递
            </li>
            <li>
              <img src={yuandian} alt="" />
              随着数字化转型在车企的不断深入应用，行业面临两大断层，制约品牌ToC能力的打造，阻碍用户服务{' '}
              <br />
              的落地，难以实现达成用户运营的目标，
              亟需总部和MAC赋能经销商加快转型。
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-4">
        <img src={detail4} alt="" />
        <div className="text">
          <h3>解决方案</h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
            从问题溯源、标准制定、培训赋能、数据分析等方面来开展工
          </p>
          <p>作。评估了经销商DCC运营状况，制定了《DCC运营手册》，开展</p>
          <p>了线上培训，VCT视频、论坛等多钟形式的线上线下辅导培训；定</p>
          <p>期分析DCC数据全面提升经销商DCC能力。</p>
        </div>
      </div>
      {/* <div className="bg-5">
        <img src={detail5} alt="" />
        <div className="text">
          <h3>产品优势</h3>
          <p>
            通过数智化工具在网电销场景下的应用，提升人员效能，形成检核闭环，从业务流程，人员能力等方面提升助力销量，形成一套一店一策的人智化管理体系
          </p>
          <ul>
            <li>
              <img src={yuandian} alt="" />
              全域数据资产采集、清洗和整合
            </li>
            <li>
              <img src={yuandian} alt="" />
              实现数据从创建到消亡全生命周期的可视化管理
            </li>
            <li>
              <img src={yuandian} alt="" />
              完善的数据资产审核流程，全面保障纳入平台中数据资产的准确性、权威性
            </li>
            <li>
              <img src={yuandian} alt="" />
              数据资产管理流程可自定义，满足各类审批场景
            </li>
            <li>
              <img src={yuandian} alt="" />
              丰富的智能元素和功能，大大缩短数据管理周期、减少成本浪费
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  )
}

export default ClassFour
