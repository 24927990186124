import React, { useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import mbanner from '@/assets/mbanner_2.png'
import peitu1 from '@/assets/peitu_1.png'
import peitu2 from '@/assets/peitu_2.png'
import peitu3 from '@/assets/peitu_3.png'
import './index.less'
const MobileDealEngine = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='mobile-dealengine'>
      <div className="deal-banner">
        <img src={mbanner} alt=''></img>
        <Fade top>
          <span className="deal-baner-title">成交引擎解决方案</span>
        </Fade>
        <Fade bottom>
          <span>
            大数据AI算法赋能终端
            <br />
            实现线索精准转化
            <br />
            大数据AI+会话智能技术
            <br />
            实现培训体系的高质量成长
            <br />
            数据智能场景
            <br />
            应用赋能成交转化与门店经营
            <br />
            车辆残值预测AI算法
            <br />
            与保值营销创新头部企业
          </span>
        </Fade>
      </div>
      <div className="deal-intro">
        <div className="intro-info">
          <Fade top>
            <span className="first-title">智慧工牌、AIDCC、线索评级</span>
          </Fade>
          <Fade bottom>
            <span className="sub-title">智能工牌</span>
            <span className="info-desc">
              建立户信息采集和沟通数据分析管理平台，补全关键转化环节客户标签数据，强化尊崇服务执行流程，优化团队服务能力，最终实现到店率和转化效率的提升
            </span>
          </Fade>
          <Fade bottom>
            <span className="sub-title">AIDCC</span>
            <span className="info-desc">
              助力DCC人员快速清洗线索、高效邀约客户及智能评分通话质量，提升获客效率
            </span>
          </Fade>
          <Fade bottom>
            <span className="sub-title">线索评级</span>
            <span className="info-desc">
              基于多触点获取的线索，进行线索清洗，利用内外部数据应用，得到客户标签，进行线索评级，智能分配到销售顾问处，提升线索邀约到店率和转化率
            </span>
          </Fade>
        </div>
        <div className="intro-banner">
          <Fade left>
            <div className="intro-banner-left">
              <img src={peitu1} alt="banner" />
            </div>
          </Fade>
          <div className="intro-banner-right">
            <Fade top>
              <img src={peitu2} alt="banner" />
            </Fade>
            <Fade bottom>
              <img src={peitu3} alt="banner" />
            </Fade>
          </div>
        </div>
      </div>
      <div className="deal-modal">
        <Fade top>
          <span className="deal-modal-title">
            提供全面的汽车营销管理咨询与配套的整体解决方案服务
            <br />
            创立新的业务生态和运营环境
          </span>
        </Fade>
        <Fade bottom>
          <div className="deal-modal-list">
            <div className="deal-modal-item">
              <span>对标调研</span>
              <span>
                深度链接汽车行业数据资源
                <br />
                通过大数据算法模型及外部标签等
                <br />
                提供对标调研服务
              </span>
            </div>
            <div className="deal-modal-item">
              <span>行业研究</span>
              <span>
                汽车行业动态
                <br />
                定期输出分析报告
                <br />
                为主机厂提供各类方案设计
              </span>
            </div>
            <div className="deal-modal-item">
              <span>解决方案</span>
              <span>
                提供全面的汽车营销管理咨询
                <br />
                与配套的整体解决方案服务
                <br />
                最大限度地服务于客户端
              </span>
            </div>
            <div className="deal-modal-item">
              <span>业务设计</span>
              <span>
                品牌官方认证二手车
                <br />
                体系框架设计及经销商方案实施
                <br />
                国内知名OEM创新自营NEV二手车
              </span>
            </div>
            <div className="deal-modal-item">
              <span>运营服务</span>
              <span>
                致力于提升汽车整车厂信息
                <br />
                赋能及经销商综合运营管理能力
                <br />
                提供汽车销售培训服务
              </span>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default MobileDealEngine
