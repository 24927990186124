import React, { useCallback, useRef, useState } from 'react'
import phoneIcon from '@/assets/icon/icon_dianhua.png'
import emailIcon from '@/assets/icon/icon_youjian.png'
import addressIcon from '@/assets/icon/icon_dizhi.png'
import './index.less'
import { Select, Button } from 'antd'
import axios from 'axios'
const { Option } = Select

const MobileFooter = () => {
  const [manner, setManner] = useState('业务合作')
  const [userName, setUserName] = useState('')
  const [phone, setPhone] = useState('')
  const [unit, setUnit] = useState('')
  const [email, setEmail] = useState('')
  const [content, setContent] = useState('')
  // const content = useRef('')
  const [isUserName, setIsUserName] = useState(true)
  const [isPhone, setIsPhone] = useState(true)
  const [isUnit, setIsUnit] = useState(true)
  const [isEmail, setIsEmail] = useState(true)
  const [isSend, setIsSend] = useState(false)
  const [phoneConrrect, setPhoneConrrect] = useState(false)
  const [emailConrrect, setEmailConrrect] = useState(false)
  const phoneRef = useRef(null)
  const emailRef = useRef(null)
  const [phoneP, setPhoneP] = useState(1)
  const [emailP, setEmailP] = useState(1)
  const [emailRes, setEmailRes] = useState(false)

  const hanlderClick = useCallback(() => {
    setPhoneP(phoneConrrect)
    setEmailP(emailConrrect)
    !phoneConrrect
      ? phoneRef.current.classList.add('text-red')
      : phoneRef.current.classList.remove('text-red')

    if (emailConrrect) {
      emailRef.current.classList.remove('text-red')
    } else {
      emailRef.current.classList.add('text-red')
    }
    setIsUserName(() => (userName === '' ? false : true))
    setIsPhone(() => (phone === '' ? false : true))
    setIsUnit(() => (unit === '' ? false : true))
    setIsEmail(() => (email === '' ? false : true))
    if (
      userName &&
      phone &&
      unit &&
      email &&
      phoneConrrect &&
      emailConrrect
    ) {
      setIsSend(true)
      sendEmail()
    }
  }, [
    userName,
    phone,
    unit,
    email,
    phoneConrrect,
    emailConrrect,
    isSend,
    content,
  ])

  const sendEmail = () => {
    const emailSendRes = {
      email,
      name: userName,
      unitName: unit,
      mobile: phone,
      businessCollaboration: manner,
      questions: content,
    }
    axios
      .post('https://www.unicdata.com/sendEmail', emailSendRes)
      .then((res) => {
        if (res.data === '发送成功') {
          setEmailRes(true)
        }
      })
      .catch(err => {
        console.log(err, 'resss')
      })
  }
  return (
    <div className="mobile-footer">
      <div className='mobile-footer-top'>
        <div className="text-item">
          <img src={addressIcon} alt="" />
          <span>
            上海市杨浦区国康路100号上海国际设计中心东楼11层
            <br />
            广州市白云区集安街 57号文创汇5号楼305室
          </span>
        </div>
        <div className="text-item">
          <img src={emailIcon} alt="" />
          <span>mkt@unicdata.com</span>
        </div>
        <div className="text-item">
          <img src={phoneIcon} alt="" />
          <span>021-65980077</span>
        </div>

        <div className="mobile-footer-form">
          <form>
            <div className="right-flex">
              <input
                className={isUserName ? '' : 'active'}
                type="text"
                placeholder="姓名(必填)"
                onInput={(e) => setUserName(e.target.value)}
              />
              <input
                onFocus={() => {
                  phoneRef.current.classList.remove('text-red')
                }}
                ref={phoneRef}
                className={isPhone ? '' : 'text-red active'}
                type="text"
                placeholder="电话(必填)"
                onInput={(e) => {
                  if (/^1[3-9]\d{9}$/.test(e.target.value)) {
                    setPhoneConrrect(true)
                    setPhone(e.target.value)
                  } else {
                    setPhoneConrrect(false)
                  }
                }}
              />
              <input
                className={isUnit ? '' : 'active'}
                type="text"
                placeholder="单位名称(必填)"
                onInput={(e) => setUnit(e.target.value)}
              />
              <input
                onFocus={() => {
                  emailRef.current.classList.remove('text-red')
                }}
                ref={emailRef}
                className={isEmail ? '' : 'text-red active'}
                type="text"
                placeholder="邮箱(必填)"
                onInput={(e) => {
                  if (
                    /^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/.test(
                      e.target.value
                    )
                  ) {
                    setEmailConrrect(true)
                    setEmail(e.target.value)
                  } else {
                    setEmailConrrect(false)
                  }
                }}
              />
            </div>
            <Select
              defaultValue={'业务合作'}
              dropdownStyle={{ backgroundColor: '#324051' }}
              onChange={(e) => setManner(e)}
            >
              <Option value="业务合作">业务合作</Option>
              <Option value="技术支持">技术支持</Option>
              <Option value="媒体联络">媒体联络</Option>
            </Select>
            <textarea
              onInput={(e) => {
                setContent(e.target.value)
              }}
              placeholder="需求/希望解决的问题"
              style={{ resize: 'none' }}
              className="big-input"
            />
            <div className="submit-box">
              {emailRes && (
                <p
                  className={emailRes ? 'active' : 'active'}
                  style={{
                    visibility: emailRes ? '' : 'hidden',
                  }}
                >
                  发送成功，感谢您的反馈！
                </p>
              )}
              {(!phoneP) ? <p className="active">手机格式有误</p> : ''}
              {(!emailP) ? <p className="active">邮箱格式有误</p> : ''}
              <Button
                className="submit"
                onClick={() => {
                  hanlderClick()
                }}
              >
                提 交
              </Button>
            </div>
          </form>
        </div>
      </div>

      <div className="buttom">
        <span>CopyRight2015-2022 All Rights Reserved</span>
        <span
          onClick={() => {
            window.open('http://www.beian.miit.gov.cn/')
          }}
        >
          沪ICP备15012794号-4
        </span>
        <span
          onClick={() => {
            window.open(
              'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002002975'
            )
          }}
        >
          沪公网安备31011002002975号
        </span>
      </div>
    </div>
  )
}

export default MobileFooter
