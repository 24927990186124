import React, { useState } from 'react'
import LogoImg from '@/assets/logo.png'
import MoreIcon from '@/assets/moreicon.png'
import CloseIcon from '@/assets/guanbi.png'
import MobileMenu from '../../menu'
import './index.less'

const MobileHeader = () => {
  const [showOpacity, setShowOpacity] = useState(false)
  const handleScroll = () => {
    if (document.documentElement.scrollTop > 70 || document.body.scrollTop > 70) {
      setShowOpacity(true)
    } else {
      setShowOpacity(false)
    }
  }
  window.addEventListener('scroll', handleScroll)

  // 菜单显示状态
  const [showMenu,setShowMenu] = useState(false)
  const handleShowMenu = ()=> {
    setShowMenu(!showMenu)
  }
  return (
    <div>
      <div
        className={showOpacity ? 'mobile-header opacity' : 'mobile-header'}
        style={{ zIndex: '9999' }}
      >
          <img src={LogoImg} alt="logo"></img>
          <span>汽车全链路数智赋能者</span>
          <img className='more-icon' src={showMenu?CloseIcon:MoreIcon} alt="icon" onClick={handleShowMenu}></img>
      </div>
      <MobileMenu className={showMenu?"":"hide"} showMenu={showMenu} className="hide"/>
    </div>
  )
}

export default MobileHeader
