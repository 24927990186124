import React, { useCallback, useEffect, useState } from 'react'
import big1 from '@/assets/salebg.png'
import zsyy from '@/assets/xk.png'
import neirong from '@/assets/neirong.png'
import Slide from 'react-reveal/Fade'
import './index.less'
const MobileSaleEngine = () => {
  const [itemIsShow, setItemIsShow] = useState(false)

  // 监听页面滚动位置
  const checkPosition = useCallback(() => {
    if (document.documentElement.scrollTop >= 600) {
      setItemIsShow(true)
    } else if (document.documentElement.scrollTop >= 1000) {
    }
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('scroll', checkPosition)
    return () => {
      window.removeEventListener('scroll', checkPosition)
    }
  }, [itemIsShow, checkPosition])

  return (
      <div className="mobile-saleengine">
        <div className="top">
          <img src={big1} alt="" className="big-name" />
          <div className="text">
            <Slide bottom>
              <div>直售引擎解决方案</div>
            </Slide>
            <Slide bottom>
              <div>面向新能源汽车的直售运营模式 </div>
            </Slide>
          </div>
        </div>

        <div className="center">
          <Slide bottom>
            <img src={neirong} alt=''></img>
          </Slide>
        </div>

        <div className="buttom">
          <div className="list">
            <Slide bottom cascade>
              <div className="list-item item-1">
                <img src={zsyy} alt="" />
                <h3>直售运营</h3>
                <div className="text">
                  <p>根据品牌定位及需求，以直联用户为目的，</p>
                  <p>挖掘客户核心价值，打造适合品牌的直售运营模式。</p>
                  <p>服务品牌：大众，别克，凯迪拉克，沃尔沃等。</p>
                </div>
              </div>
              <div className="list-item item-2">
                <img src={zsyy} alt="" />
                <h3>获客模式</h3>
                <div className="text">
                  <p>基于公域转私域的运营模式，</p>
                  <p>圈定私域用户群体精准清晰用户画像，</p>
                  <p>再次投放于公域流量获客，形成公私域双循环运营。</p>
                </div>
              </div>
              <div className="list-item item-3">
                <img src={zsyy} alt="" />
                <h3>数字营销创新与应用</h3>
                <div className="text">
                  <p>基于用户大数据，获得初步客户人群画像，</p>
                  <p>做数字展厅，企微，小程序，朋友圈和抖音的精准投放，</p>
                  <p>得到流量转化数据后，精准定位客户人群画像。</p>
                </div>
              </div>
              <div className="list-item item-4">
                <img src={zsyy} alt="" />
                <h3>挖掘客户的价值</h3>
                <div className="text">
                  <p>通过用户关系链、用户荣誉感、四个维度，收益价值、</p>
                  <p>时间和关注力四大维度去有效挖掘用户各项价值，</p>
                  <p>深度满足用户的刚性需求，留住用户。</p>
                </div>
              </div>
              <div className="list-item item-5">
                <img src={zsyy} alt="" />
                <h3>特色服务打造</h3>
                <div className="text">
                  <p>细分核心人群需求，试乘试驾场景化，</p>
                  <p>迭代MOT核心流程，为客户打造尊崇服务体验，</p>
                  <p>提升联营销量转化率。</p>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </div>
  )
}

export default MobileSaleEngine
