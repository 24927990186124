import detail1 from '../../../../assets/bg_1detail.jpg'
import detail2 from '../../../../assets/bg_2detail.jpg'
import detail3 from '../../../../assets/bg_3detail.jpg'
import detail4 from '../../../../assets/bg_4detail.jpg'
import detail5 from '../../../../assets/bg_5detail.jpg'
import yuandian from '../../../../assets/圆点.png'
import '../index.less'
const ClassOne = () => {
  return (
    <div className="detail-item-1">
      <div className="bg-1">
        <img src={detail1} alt="" />
        <div className="text">
          <h2>帮助某德系汽车品牌</h2>
          <h2>搭建车联移动数据管理与应用平台</h2>
        </div>
      </div>
      <div className="bg-2">
        <img src={detail2} alt="" />
        <div className="text">
          <h3>背景与目标</h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
            知名车企想要从数据层面解决企业烟囱式业务系统的数据
          </p>
          <p>同步、采集、传输、加工、存储、共享、治理等方面面临的挑</p>
          <p>战。通过大数据管理平台企业可以实现异构数据源集成，数据</p>
          <p>质量检测、规范建模，元数据管理以及血缘链路追踪，有效利</p>
          <p>用数据资产实现企业目标。</p>
        </div>
      </div>
      <div className="bg-3">
        <img src={detail3} alt="" />
        <div className="text" style={{ right: '60px' }}>
          <h3>痛点及挑战</h3>

          <ul>
            <li>
              <img src={yuandian} alt="" />
              为了满足业务快速发展的需求，企业构建了太多烟囱式应用。应
              用间信息孤岛严重，数据难以流通。应用 <br />
              间能力难以复用，资源浪费严重；
            </li>
            <li>
              <img src={yuandian} alt="" />
              缺少统一的业务系统集成途径，数据格式、协议多样化，难以传输和集成；
              数据质量不高，难以高效利用。
            </li>
            <li>
              <img src={yuandian} alt="" />
              管理不规范：数据模型管理缺乏规范化体系，大数据平台内数据的技术、业务属性不清晰，没有完善的数据
              <br />
              型管理体系与规范。
            </li>
            <li>
              <img src={yuandian} alt="" />
              模缺少与合作伙伴分享数据和业务能力服务的便捷途径；
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-4">
        <img src={detail4} alt="" />
        <div className="text">
          <h3>解决方案</h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
            大数据管理平台提供数据统一集成、标准数据模型搭建、
          </p>
          <p>数据加工和存储，数据资源调度服务、数据治理，数据共享服</p>
          <p>服务，帮助客户逐步实现数据规范化和标准化。大数据管理平台</p>
          <p>不仅仅是技术工具，更是重要的数据运营平台，降低业务对技</p>
          <p>术依赖，充分发挥业务创新潜能。</p>
        </div>
      </div>
      <div className="bg-5">
        <img src={detail5} alt="" />
        <div className="text">
          <h3>产品优势</h3>
          <p>
            大数据管理平台能够实现数据的全生命周期管理，帮助各角色用户快速识别各业务系统的数据，并进行统一管理，最后将标
            准的、高质量、 高准确性的数据提供
            <br />
            给业务人员和前台业务系统， 它具备以下优势：
          </p>
          <ul>
            <li>
              <img src={yuandian} alt="" />
              全域数据资产采集、清洗和整合
            </li>
            <li>
              <img src={yuandian} alt="" />
              实现数据从创建到消亡全生命周期的可视化管理
            </li>
            <li>
              <img src={yuandian} alt="" />
              完善的数据资产审核流程，全面保障纳入平台中数据资产的准确性、权威性
            </li>
            <li>
              <img src={yuandian} alt="" />
              数据资产管理流程可自定义，满足各类审批场景
            </li>
            <li>
              <img src={yuandian} alt="" />
              丰富的智能元素和功能，大大缩短数据管理周期、减少成本浪费
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ClassOne
