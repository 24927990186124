import React, { useCallback, useEffect, useState } from 'react'
import banner from '../../../assets/banner.gif'
import carbg from '../../../assets/carbg.png'
import Slide from 'react-reveal/Fade'
// import v1 from '../../../assets/4_1676599955.mp4'
import './index.less'
import ReactPlayer from 'react-player'
const OpenPlatformAI = () => {
  const [itemIsShow, setItemIsShow] = useState(false)

  // 监听页面滚动位置
  const checkPosition = useCallback(() => {
    // console.log(document.documentElement.scrollTop)
    if (document.documentElement.scrollTop >= 600) {
      setItemIsShow(true)
    } else if (document.documentElement.scrollTop >= 1000) {
    }
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('scroll', checkPosition)
    return () => {
      window.removeEventListener('scroll', checkPosition)
    }
  }, [itemIsShow, checkPosition])

  return (
    <div className="carAI">
      <div className="top">

        <img src={banner} alt="" />


        <div className="text">
          <Slide bottom>
            <h2>AI平台</h2>
          </Slide>
          <Slide bottom>
            <div>车企数智化能力基座平台<br />
              集创造、管理、展示、应用AI能力的一站式企业级AI平台<br />
              助力车企迅速建立数字化中台体系，实现AI在汽车价值链的快速落地 </div>
          </Slide>
        </div>
      </div>

      <div className="buttom">
        <div className="list">

          <img src={carbg} alt="" />

          <Slide bottom>
            <div className="list-item item-1">
              <p className="list-itemP">一站式AI开发平台</p>
              <div className="content">
                <div className="content1">
                  <div className="content1-top">
                    {/* <ReactPlayer
                      className='react-player'
                      url='https://media.w3.org/2010/05/sintel/trailer_hd.mp4'
                      width='100%'
                      height='100%'
                      playing={false}
                      controls
                    /> */}
                  </div>
                  <div className="content1-bot">自定义模型开发</div>
                </div>
                <div className="content2">
                  <div className="content1-top">
                    {/* <ReactPlayer
                      className='react-player'
                      url='https://media.w3.org/2010/05/sintel/trailer_hd.mp4'
                      width='100%'
                      height='100%'
                      playing={false}
                      controls
                    /> */}
                  </div>
                  <div className="content1-bot">自动化机器学习</div>
                </div>
                <div className="content3">
                  <div className="content1-top">    </div>
                  <div className="content1-bot">云开发环境监测</div>
                </div>
              </div>
            </div>
            <div className="list-item item-2">
              <p className="list-itemP">自驾云开发平台</p>
              <div className="content">
                <div className="content1">
                  <div className="content1-top">    </div>
                  <div className="content1-bot">视频抽帧目标追踪标注</div>
                </div>
                <div className="content2">
                  <div className="content1-top">

                  </div>
                  <div className="content1-bot">自驾场景图像分割标注</div>
                </div>
                <div className="content3">
                  <div className="content1-top">     </div>
                  <div className="content1-bot">3D点云标注</div>
                </div>
              </div>
            </div>
            <div className="list-item item-3">
              <p className="list-itemP">智能会话开发平台</p>
              <div className="content">
                <div className="content1">
                  <div className="content1-top">    </div>
                  <div className="content1-bot">汽车产业知识图谱</div>
                </div>
                <div className="content2">
                  <div className="content1-top">    </div>
                  <div className="content1-bot">车载语音分割、语音识别</div>
                </div>
                <div className="content3">
                  <div className="content1-top">    </div>
                  <div className="content1-bot">客服智能问答</div>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </div>
  )
}

export default OpenPlatformAI
