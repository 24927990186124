import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { isPc } from './utils/index'
import CommenHeader from './components/header'
import CommenFooter from './components/footer'
import Academy from './pages/academy'
import Home from './pages/home'
import Case from './pages/case'
import Ecology from './pages/ecology'
import Datacenter from './pages/solution/datacenter'
import DealEngine from './pages/solution/dealengine'
import SaleEngine from './pages/solution/saleengine'
import OpenPlatformAI from './pages/solution/carAI'
import AnnotationPlatform from './pages/solution/annotationPlatform'
import CaseDetail from './pages/case/case-detail'
import NavList from '@/components/navList/index.js'
import NotFound from './pages/404'
// 移动端组件
import MobileHome from './pages/mobile/home'
import MobileCase from './pages/mobile/case'
import MobileCaseDetail from './pages/mobile/case/case-detail'
import MobileHeader from './pages/mobile/components/header'
import MobileFooter from './pages/mobile/components/footer'
import MobileNavList from './pages/mobile/components/navList/index.js'
import MobileDataCenter from './pages/mobile/solution/datacenter'
import MobileDealEngine from './pages/mobile/solution/dealengine'
import MobileSaleEngine from './pages/mobile/solution/saleengine'
import MobileOpenPlatformAI from './pages/mobile/solution/carAI'
import MoblieAnnotationPlatform from './pages/mobile/solution/annotationPlatform'
import MobileAcademy from './pages/mobile/academy'
import MobileEcology from './pages/mobile/ecology'
import './App.css'

function App() {
  return (
    <BrowserRouter>
      {/* 头部 */}
      {isPc() ? <CommenHeader /> : <MobileHeader />}
      {/* 内容 */}
      <Routes>
        {/* 首页 */}
        <Route path="/" element={isPc() ? <Home /> : <MobileHome />} />
        {/* 众调研究院 */}
        <Route path="/academy" element={isPc() ? <Academy /> : <MobileAcademy />} />
        {/* 成功案例 */}
        <Route path="/case" element={isPc() ? <Case /> : <MobileCase />} />
        {/* 案例详情 */}
        <Route path="/case-detail" element={isPc() ? <CaseDetail /> : <MobileCaseDetail />} />
        {/* 公司生态 */}
        <Route path="/ecology" element={isPc() ? <Ecology /> : <MobileEcology />} />
        {/* 数据中台 */}
        <Route path="/datacenter" element={isPc() ? <Datacenter /> : <MobileDataCenter />} />
        {/* 成交引擎 */}
        <Route path="/dealengine" element={isPc() ? <DealEngine /> : <MobileDealEngine />} />
        {/* 值售引擎 */}
        <Route path="/saleengine" element={isPc() ? <SaleEngine /> : <MobileSaleEngine />} />
        {/* AI中台 */}
        <Route path="/AIOpenPlatform" element={isPc() ? <OpenPlatformAI /> : <MobileOpenPlatformAI />} />
        {/* 标注平台 */}
        <Route path="/annotationPlatform" element={isPc() ? <AnnotationPlatform /> : <MoblieAnnotationPlatform />} />
        {/* 404页面 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* 脚部 */}
      {isPc() ? <CommenFooter /> : <MobileFooter />}
      {/* 悬浮窗 */}
      {isPc() ? <NavList /> : <MobileNavList />}

    </BrowserRouter>
  )
}

export default App
