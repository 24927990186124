import React, { useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import './index.less'
import bg1 from '../../../assets/banner_biaozhu.png'
import bg2 from '../../../assets/banner_tp.png'
import bg3 from '../../../assets/banner_yp.png'
import bg4 from '../../../assets/banner_wb.png'
import bg5 from '../../../assets/banner_sp.png'
import bg6 from '../../../assets/banner_zd.png'

const AnnotationPlatform = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='annotationall'>
      <div className='annotation-top'>
        <div className="annotation-banner">

          <img src={bg1} alt="" />


          <div className="annotation-baner-title">标注平台</div>
          <Fade bottom>
            <div className="annotation-baner-txt">
              是一款智能化数据标注平台，支持图像、文本、视频、音频等多种数据类型的标注
            </div>
          </Fade>
          <Fade top>
            <div className="annotation-baner-toptagall">
              <a className="annotation-baner-toptag" href="#tupian"></a>
              <a className="annotation-baner-toptag" href="#yinpin"></a>
              <a className="annotation-baner-toptag" href="#wenben"></a>
              <a className="annotation-baner-toptag" href="#shipin"></a>
              <a className="annotation-baner-toptag" href="#zidong"></a>
            </div>
          </Fade>
        </div>
        <div className="annotation-banner1">

          <img src={bg2} alt="" />


          <a name="tupian" className='maodian' />
          <Fade top>
            <div className="annotation-baner-title">图片标注</div>
            <div className="annotation-baner-tag">
              <div className="annotation-baner-intag">图像分类</div>
              <div className="annotation-baner-intag">目标检测</div>
              <div className="annotation-baner-intag">图像分割</div>
              <div className="annotation-baner-intag">图像OCR</div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="annotation-baner-txt">
              定制基于图像进行多样化分析的AI模型，实现图像内容理解<br />
              分类、图中物体检测定位等，适用于图片内容检索、车<br />
              道线标注、交通障碍物等场景
            </div>
          </Fade>
        </div>
        <div className="annotation-banner2">

          <img src={bg3} alt="" />


          <a name="yinpin" className='maodian' />
          <Fade top>
            <div className="annotation-baner-title">音频标注</div>
            <div className="annotation-baner-tag">
              <div className="annotation-baner-intag">音频OCR</div>
              <div className="annotation-baner-intag">音频分割</div>
              <div className="annotation-baner-intag">音频分类</div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="annotation-baner-txt">
              定制语音识别模型，精准识别音频中的文字，适用于数据采集录入、语音指令、<br />
              呼叫中心等场景，以及定制音频分类模型，用于区分不同声音类别
            </div>
          </Fade>
        </div>
        <div className="annotation-banner3">
          <a name="wenben" className='maodian' />

          <img src={bg4} alt="" />


          <Fade top>
            <div className="annotation-baner-title">文本标注</div>
            <div className="annotation-baner-tag">
              <div className="annotation-baner-intag">短文本相似度对比</div>
              <div className="annotation-baner-intag">多标签文本标注</div>
              <div className="annotation-baner-intag">单标签文本标注</div>
              <div className="annotation-baner-intag">实体关系分析</div>
              <div className="annotation-baner-intag">文本实体抽取</div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="annotation-baner-txt">
              以NLP技术为基座，提供多种NLP模型的零门槛定制与应用能<br />
              力，广泛适用于文本分类、内容审核、内容对比、文章查重、<br />
              关系抽取等各类文本处理与分析的场景
            </div>
          </Fade>
        </div>
        <div className="annotation-banner4">
          <a name="shipin" className='maodian' />

          <img src={bg5} alt="" />


          <Fade top>
            <div className="annotation-baner-title">视频标注</div>
            <div className="annotation-baner-tag">
              <div className="annotation-baner-intag">视频分类</div>
              <div className="annotation-baner-intag">视频目标跟踪</div>
              <div className="annotation-baner-intag">视频OCR</div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="annotation-baner-txt">
              定制化分析视频片段内容、跟踪并标记视频中特定目标，适用于<br />
              车流/人流统计、行车动轨迹分析、视频内容审核等场景
            </div>
          </Fade>
        </div>
        <div className="annotation-banner5">
          <a name="zidong" className='maodian' />

          <img src={bg6} alt="" />


          <Fade top>
            <div className="annotation-baner-title">自动驾驶标注</div>
            <div className="annotation-baner-tag">
              <div className="annotation-baner-intag">2D图像语义分割</div>
              <div className="annotation-baner-intag">3D点云语义分割</div>
              <div className="annotation-baner-intag">2D交通对象tracking</div>
              <div className="annotation-baner-intag">3D点云标注</div>
              <div className="annotation-baner-intag">3D点云tracking</div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="annotation-baner-txt">
              过自主研发的2D和3D标注工具，将激光测量采集的3D信息，<br />
              绘建成高精度的3D模型，以便更好的为自动驾驶服务
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default AnnotationPlatform
