import React, { useCallback, useEffect, useState } from 'react'
import big1 from '../../../assets/big1.jpg'
import big2 from '../../../assets/big2.jpg'
import big3 from '../../../assets/big3.jpg'
import tp from '../../../assets/tp.png'
import zjc from '../../../assets/zjc.png'
import zdkj from '../../../assets/zdkj.png'
import hzpp from '../../../assets/hzpp.png'
import yxxg from '../../../assets/yxxg.png'
import cj from '../../../assets/cj.png'
import tp2 from '../../../assets/tp2.png'
import zsyy from '../../../assets/zsyy.png'
import hkms from '../../../assets/hkms.png'
import szyx from '../../../assets/szyx.png'
import wjkh from '../../../assets/wjkh.png'
import tsfw from '../../../assets/tsfw.png'
import Slide from 'react-reveal/Fade'
import './index.less'
const SaleEngine = () => {
  const [itemIsShow, setItemIsShow] = useState(false)

  // 监听页面滚动位置
  const checkPosition = useCallback(() => {
    // console.log(document.documentElement.scrollTop)
    if (document.documentElement.scrollTop >= 600) {
      setItemIsShow(true)
    } else if (document.documentElement.scrollTop >= 1000) {
    }
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('scroll', checkPosition)
    return () => {
      window.removeEventListener('scroll', checkPosition)
    }
  }, [itemIsShow, checkPosition])

  return (
    <div>
      <div className="saleengine">
        <div className="top">

          <img src={big1} alt="" className="big-name" />

          <div className="text">
            <Slide bottom>
              <h2>直售引擎解决方案</h2>
            </Slide>
            <Slide bottom>
              <div>面向新能源汽车的直售运营模式 </div>
            </Slide>
          </div>
        </div>
        <div className="center">

          <img src={big2} alt="" />

          {itemIsShow && (
            <div className="saleengine-list">
              <Slide left>
                <div className="saleengine-item-1">
                  <img src={tp} alt="" />
                </div>
              </Slide>
              <Slide bottom>
                <div className="saleengine-item-2">
                  <img src={zdkj} alt="" />
                  <div className="text">
                    <h4>众调科技</h4>
                    <p>三大垂媒的运维管理与提升</p>
                    <p>新媒体获客运营与创新</p>
                    <p>公私域循环运营管控</p>
                    <p>数字化工具在服务流程中的运用</p>
                    <p>建立标准化、统一的经营</p>
                    <p>能力评价标准</p>
                  </div>
                </div>
              </Slide>
              <Slide top>
                <div className="saleengine-item-3">
                  <img src={zjc} alt="" />
                  <div className="text">
                    <h4>主机厂营销样板</h4>
                    <p>流程、人员的直接把控</p>
                  </div>
                </div>
              </Slide>
              <Slide bottom>
                <div className="saleengine-item-4">
                  <img src={hzpp} alt="" />
                  <div className="text">
                    <h4>合作品牌</h4>
                    <p>对别克、凯迪拉克、沃尔沃、大众、吉利等</p>
                    <p>品牌合作商</p>
                    <p>流程、人员的直接把控</p>
                  </div>
                </div>
              </Slide>
              <Slide top>
                <div className="saleengine-item-5">
                  <img src={yxxg} alt="" />
                  <div className="text">
                    <p>线索管控</p>
                    <p>经营管理</p>
                    <h4>营销效果的极致发挥</h4>
                    <p>品牌服务</p>
                  </div>
                </div>
              </Slide>
              <Slide bottom>
                <div className="saleengine-item-6">
                  <img src={cj} alt="" />
                  <div className="text">
                    <h4>成交</h4>
                  </div>
                </div>
              </Slide>
              <Slide right>
                <div className="saleengine-item-7">
                  <img src={tp2} alt="" />
                </div>
              </Slide>
            </div>
          )}
        </div>

        <div className="buttom">
          <img src={big3} alt="" />

          <div className="list">
            <Slide top cascade>
              <div className="item-1">
                <img src={zsyy} alt="" />
                <h3>直售运营</h3>
                <div className="text">
                  <p>根据品牌定位及需求，</p>
                  <p>以直联用户为目的，挖掘客户核心价值，</p>
                  <p>打造适合品牌的直售运营模式。</p>
                  <p>服务品牌：大众，别克，凯迪拉克，沃尔沃等。</p>
                </div>
              </div>
              <div className="item-2">
                <img src={hkms} alt="" />
                <h3>获客模式</h3>
                <div className="text">
                  <p>基于公域转私域的运营模式，</p>
                  <p>圈定私域用户群体精准清晰用户画像，</p>
                  <p>再次投放于公域流量获客，形成公私域双循环运营。</p>
                </div>
              </div>
              <div className="item-3">
                <img src={szyx} alt="" />
                <h3>数字营销创新与应用</h3>
                <div className="text">
                  <p>基于用户大数据，获得初步客户人群画像，</p>
                  <p>做数字展厅，企微，小程序，朋友圈和抖音的精准投放，</p>
                  <p>得到流量转化数据后，精准定位客户人群画像。</p>
                </div>
              </div>
              <div className="item-4">
                <img src={wjkh} alt="" />
                <h3>挖掘客户的价值</h3>
                <div className="text">
                  <p>通过用户关系链、用户荣誉感、四个维度，收益价值、</p>
                  <p>时间和关注力四大维度去有效挖掘用户各项价值，</p>
                  <p>深度满足用户的刚性需求，留住用户。</p>
                </div>
              </div>
              <div className="item-5">
                <img src={tsfw} alt="" />
                <h3>特色服务打造</h3>
                <div className="text">
                  <p>细分核心人群需求，试乘试驾场景化，</p>
                  <p>迭代MOT核心流程，为客户打造尊崇服务体验，</p>
                  <p>提升联营销量转化率。</p>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SaleEngine
