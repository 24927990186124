import detail1 from '@/assets/detailbg1.png'
import detail2 from '@/assets/detailbg2.png'
import detail4 from '@/assets/detailbg4.png'
import '../index.less'
const ClassFive = () => {
  return (
    <div className="m-detail-item-1">
      <div className="bg-1">
        <img src={detail1} alt="" />
        <div className="text">
          <h2>帮助某一线品牌打造客户</h2>
          <h2>数据平台和自动化营销平台项目</h2>
        </div>
      </div>
      <div className="bg-2">
        <img src={detail2} alt="" />
        <div className="text">
          <h3>背景与目标</h3>
          <span>
          品牌面临数字化转型的关键阶段，数字化赋能蓄势待发，
          通过本项目将构建客户数据平台和自动化营销平台，实现业务赋能。
          </span>
        </div>
      </div>
      <div className="bg-4">
        <img src={detail4} alt="" />
        <div className="text">
          <h3>解决方案</h3>
          <span>
          品牌汽车客户数据平台和自动化营销平台基于品牌Newbie
          和Data Lake, 助力品牌构建业务与数据双中台驱动数字化转型基
          于品牌现有系统与数据平台，打造数据与数字驱动的CDP与MA
          平台，构建品牌特色的标签与指标体系，实现360客户画像与全，
          渠道智能交互通过产品与数据智能实现全链路数智赋能与效果跟
          踪提升研发并实践算法驱动的基于场景的数字化产品与服务，步
          骤实现品牌车主的客户体验提升与销售服务的营销效果转化 
          </span>
        </div>
      </div>
    </div>
  )
}

export default ClassFive
