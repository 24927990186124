import './index.less'
import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import Slide from 'react-reveal/Slide'
import aliyun from '@/assets/aliyun-logo.png'
import fudan from '@/assets/fudan.png'
import IBM from '@/assets/IBM.png'
import baidu from '@/assets/baidu.png'
import zhongguanchun from '@/assets/zhongguanchun.png'
import hendaochuxing from '@/assets/hendaochuxing.png'
import academybg1 from "@/assets/academybg1.png"

export default class MobielAcademy extends Component {
    state = {
        isShow: true,
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div className="mobile-academy">
                <div className="research">
                    <img src={academybg1} alt=''></img>
                    <Fade top><span className='research-title'>众调研究院</span></Fade>
                    <Fade bottom>
                        <div className='research-subtitle'>
                            <span>[ 人工智能 ]</span>
                            <span>[ 自动驾驶 ]</span>
                            <span>[ 汽车元宇宙 ]</span>
                        </div>
                    </Fade>
                </div>
                <div className="research field">
                    <Fade top><span className='research-title'>软著专利与研究领域</span></Fade>
                    <Fade bottom>
                        <div className='research-subtitle'>
                            <span><span className="bigFootSizeSpan">40+</span>产品相关软件著作及专利</span>
                            <span>[ 机器视觉 ] [ 驾驶标注 ]</span>
                            <span>[ 大数据与人工智能 ] [ 渠道管理与网络规划 ]</span>
                        </div>
                    </Fade>
                </div>

                <div className="ability">
                    <Fade bottom><span className='ability-title'>学术研究能力</span></Fade>
                    <div className='ability-content'>
                        <Fade bottom>
                            <span>
                                众调科技研发团队由CEO谢鹏带队，研发团队包括：产品设计、数据架构、场景开发、算法模型、解决方案、研发实施、应用创新、自动驾驶。聚集了具备汽车行业knowhow的行业专家，集认知和研发的技术闭环，拥有具有核心竞争力的行业级标签和模型库，形成以AI为核心面向智能营销与智驾研发的应用。公司已申请2021-2022年度上海市数字化转型项目、上海市信息化发展专项资金项目、上海张江国家自主创新示范区专项发展资金项目等多个市级课题和项目。截止至今，公司已获得发明专利1项、软件著作权42项、商标注册39件等核心知识产权证书。
                            </span>
                            <span>
                                众调科技董事长郑鑫博士/教授带领的团队曾经获得5项以上国家自然科学基金面上和国家级重点项目资助，特别是其主持的课题《大数据驱动的产品用户挖掘模型和产品优化策略研究》研究的算法和模型获得国内外学术界专家教授的一致高度认可，并发表国际顶级学术A+类期刊比如MIS Quarterly等期刊优秀论文3篇，获得专利和软件著作权2项；郑鑫博士和叶明海教授联合百度公司一起研发《大数据驱动的用户画像标准和意向指数》也获得学术界和实业界的高度认可；郑鑫博士教授作为上海市政府人工智能领域的决策咨询专家，其领衔主持研究的《上海人工智能发展的瓶颈和培育领军力量研究》等课题成果得到了上海市政府领导的高度认可，曾获得上海市政府决策咨询研究成果奖一、二、三等奖等奖项。
                            </span>
                            <span>
                                郑鑫博士在国内外顶级学习期刊发表学术论文20余篇，专著2本，并和美国南加州大学、斯坦福大学、MIT等国际知名高校的顶尖大学教授结成了长期的学术合作伙伴关系，为后续的研究、开发和人才培养奠定了强大的学术基础和高地。
                            </span>
                        </Fade>
                    </div>
                </div>

                <div className="mechanism">
                    <span className="mechanism-title">
                        <Fade bottom>合作研究院委员会</Fade>
                        <Fade bottom>研究机构、大学研究机构</Fade>
                    </span>
                    <div className="mechanismImg">
                        <Slide bottom>
                            <div className='mechanismImg-top'>
                                <div className='mechanismImg-item'>
                                    <img src={fudan} alt="/" />
                                    <div>复旦大学</div>
                                </div>
                                <div className='mechanismImg-item'>
                                    <img src={IBM} alt="/" />
                                    <div>同济大学</div>
                                </div>
                                <div className='mechanismImg-item'>
                                    <img src={aliyun} alt="/" />
                                    <div>阿里云</div>
                                </div>
                            </div>
                        </Slide>
                        <Slide bottom>
                            <div className='mechanismImg-bottom'>
                                <div className='mechanismImg-item'>
                                    <img src={baidu} alt="/" />
                                    <div>百度研究院</div>
                                </div>
                                <div className='mechanismImg-item'>
                                    <img src={zhongguanchun} alt="/" />
                                    <div>中关村大数据产业联盟</div>
                                </div>
                                <div className='mechanismImg-item'>
                                    <img src={hendaochuxing} alt="/" />
                                    <div>同济大学-享道出行移动大数据人工智能实验室</div>
                                </div>
                            </div>
                        </Slide>
                    </div>
                </div>
            </div>
        )
    }
}
