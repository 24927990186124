import detail1 from '../../../../assets/bg_1detail.jpg'
import detail2 from '../../../../assets/bg_2detail.jpg'
import detail3 from '../../../../assets/bg_3detail.jpg'
import detail4 from '../../../../assets/bg_4detail.jpg'
import detail5 from '../../../../assets/bg_5detail.jpg'
import yuandian from '../../../../assets/圆点.png'
const ClassSix = () => {
  return (
    <div className="detail-item-1  detail-item-2">
      <div className="bg-1">
        <img src={detail1} alt="" />
        <div className="text">
          <h2>帮助豪华品牌打造内容中台</h2>
        </div>
      </div>
      <div className="bg-2">
        <img src={detail2} alt="" />
        <div className="text">
          <h3>背景与目标</h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
            在互联网大数据洪流下，用户运营从流量获取到转化的模
          </p>
          <p>式已掀起潮流，这股浪潮使客户轻松接触更多营销工具的同时，</p>
          <p>也促进了公域转私域的效率，而私域流量内容的统一化、专业</p>
          <p>化打造将成为实现用户决策转化的关键和手段 </p>
        </div>
      </div>
      <div className="bg-3">
        <img src={detail3} alt="" />
        <div className="text">
          <h3>痛点及挑战</h3>。
          <ul>
            <li>
              目前现有的品牌内部品宣内容较为散乱，渠道整合不够充沛，导致经销商和消费者无法快速
              <br />
              精准地 找到自己想要的信息或无法从模糊信息中获取官方专业的内容
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-4">
        <img src={detail4} alt="" />
        <div className="text">
          <h3>解决方案</h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
            打造内容中台进行素材管理，架起经销商与消费者之间畅通无阻
          </p>
          <p>的沟通桥梁与信息集成站。持续不断的创作制造、收集客户想看的内</p>
          <p>容，并不断优化维护内容质量；快速、智能化地将内容精准推送给客</p>
          <p>户，满足客户需求，赋能经销商人员销售能力。</p>
        </div>
      </div>
      <div className="bg-5">
        <img src={detail5} alt="" />
        <div className="text">
          <h3>项目成效</h3>
          <p style={{ marginLeft: '100px' }}>
            整合各渠道信息进行加工编辑，输出官方专业且权威的一手信息。截止目前，已为经销商提供上千条素材，包含品牌资讯、
            产品知识、 <br />
            培训内容、车主口碑、网络测评等各种来源的内容。另开发了近400条话术，覆盖销售、售后全场景。之后继续推出智能搜索、智能回
            <br />
            复、客户画像等功能，实现凯迪客户全生命周期和全场景的内容精细匹配
          </p>
          {/* <ul>
            <li>
              <img src={yuandian} alt="" />
              全域数据资产采集、清洗和整合
            </li>
            <li>
              <img src={yuandian} alt="" />
              实现数据从创建到消亡全生命周期的可视化管理
            </li>
            <li>
              <img src={yuandian} alt="" />
              完善的数据资产审核流程，全面保障纳入平台中数据资产的准确性、权威性
            </li>
            <li>
              <img src={yuandian} alt="" />
              数据资产管理流程可自定义，满足各类审批场景
            </li>
            <li>
              <img src={yuandian} alt="" />
              丰富的智能元素和功能，大大缩短数据管理周期、减少成本浪费
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  )
}

export default ClassSix
