import './index.less'
import navQp from '../../assets/nav-qp.png'
import gzh from '../../assets/gzh.jpg'
import mp from '../../assets/mp.png'
import { useCallback, useEffect, useState, useMemo } from 'react'
import ewm from '../../assets/erweima.png'
import ewm2 from '../../assets/erweima-2.png'
import sjt from '../../assets/shangjiantou.png'
import sjt2 from '../../assets/shangjiantou-2.png'
const NavList = () => {
  const [wechat, setWechat] = useState(false)
  const [bc, setBc] = useState(false)
  const [showTop, setShowTop] = useState(false)
  const [showTopWhite, setShowTopWhite] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', checkPosition)
    return () => {
      window.removeEventListener('scroll', checkPosition)
    }
  }, [showTop])

  // 监听页面滚动位置
  const checkPosition = useCallback(() => {
    document.documentElement.scrollTop >= 800
      ? setShowTop(true)
      : setShowTop(false)
  }, [showTop])
  return (
    <div className="nav-list">
      {showTop && (
        <div
          className="nav-item-1"
          onClick={() => {
            document.documentElement.scrollTop = 0
          }}
          onMouseMove={() => {
            setShowTopWhite(true)
          }}
          onMouseLeave={() => {
            setShowTopWhite(false)
          }}
        >
          <img src={showTopWhite ? sjt2 : sjt} alt="" />
        </div>
      )}
      <div
        className="nav-item-2"
        onMouseMove={() => {
          setWechat(true)
        }}
        onMouseLeave={() => {
          setWechat(false)
        }}
      >
        <img src={wechat ? ewm2 : ewm} alt="" className="tp" />
        <img
          src={navQp}
          alt=""
          className="qp"
          style={{ display: wechat ? 'block' : 'none' }}
        />
        <div className="pic" style={{ display: wechat ? 'block' : 'none' }}>
          <img src={gzh} alt="" />
          <p className="text">关注微信公众号</p>
        </div>
      </div>
      <div
        className="nav-item-3"
        onMouseMove={() => {
          setBc(true)
        }}
        onMouseLeave={() => {
          setBc(false)
        }}
      >
        <img src={bc ? ewm2 : ewm} alt="" className="tp" />
        <img
          src={navQp}
          alt=""
          className="qp"
          style={{ display: bc ? 'block' : 'none' }}
        />
        <div className="pic" style={{ display: bc ? 'block' : 'none' }}>
          <img src={mp} alt="" />
          <p className="text">众调汽车大数据</p>
        </div>
      </div>
    </div>
  )
}

export default NavList
