import React, { useState, useEffect, useRef } from 'react'
import { Carousel, Tabs, Button, Modal } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Fade from 'react-reveal/Fade'

import bannerdong from '@/assets/banner_dong.gif'
import banner2 from '@/assets/banner_2.jpg'
import banner3 from '@/assets/banner_3.jpg'
import banner from '../../assets/banner.gif'
import bg1 from '../../assets/banner_biaozhu.png'

// 汽车厂商图片
import logo1 from '@/assets/car/logo_1.png'
import logo2 from '@/assets/car/logo_2.png'
import logo3 from '@/assets/car/logo_3.png'
import logo4 from '@/assets/car/logo_4.png'
import logo5 from '@/assets/car/logo_5.png'
import logo6 from '@/assets/car/logo_6.png'
import logo7 from '@/assets/car/logo_7.png'
import logo8 from '@/assets/car/logo_8.png'
import logo9 from '@/assets/car/logo_9.png'
import logo10 from '@/assets/car/logo_10.png'
import logo11 from '@/assets/car/logo_11.png'
import logo12 from '@/assets/car/logo_12.png'
import logo13 from '@/assets/car/logo_13.png'
import logo14 from '@/assets/car/logo_14.png'
// 行业合作伙伴图片
import partner1 from '@/assets/partner/logo_1.png'
import partner2 from '@/assets/partner/logo_2.png'
import partner3 from '@/assets/partner/logo_3.png'
import partner4 from '@/assets/partner/logo_4.png'
import partner5 from '@/assets/partner/logo_5.png'
import partner6 from '@/assets/partner/logo_6.png'
import partner7 from '@/assets/partner/logo_7.png'
import partner8 from '@/assets/partner/logo_8.png'
import partner9 from '@/assets/partner/logo_9.png'
import partner10 from '@/assets/partner/logo_10.png'
import partner11 from '@/assets/partner/logo_11.png'
import partner12 from '@/assets/partner/logo_12.png'
import partner13 from '@/assets/partner/logo_13.png'
import partner14 from '@/assets/partner/logo_14.png'
// 高校研究机构
import school1 from '@/assets/school/logo_1.png'
import school2 from '@/assets/school/logo_2.png'
import school3 from '@/assets/school/logo_3.png'
import school4 from '@/assets/school/logo_4.png'
import school5 from '@/assets/school/logo_5.png'
import './index.less'
const { TabPane } = Tabs
const Home = () => {
  useEffect(() => {
    sessionStorage.setItem("first", "0")
    if (sessionStorage.getItem("first") && sessionStorage.getItem("first") == 0) {
      const modal = Modal.info({
        icon: null,
        okText: "我已知晓",
        width: 800,
        className: "amodal",
        bodyStyle: { marginTop: "10%" },
        content: <div style={{ padding: 20 }}>
          <h2 style={{ textAlign: 'center' }}>郑重声明</h2>
          <p style={{ textAlign: "justify" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为维护良好网络舆论秩序、依法严厉打击网络谣言，切实提升广大网民上网安全感和满意度。根据公安部打击整治网络谣言专项行动统一部署，上海市公安局依法严厉打击造谣传谣违法行为，针对网上恶意炒作、造谣生事等网络乱象开展集中打击整治，查处了一批利用个人、自媒体账号发布网络谣言的违法人员。现公布几起典型案例：
          </p>
          <p style={{ textAlign: "justify" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href='https://mp.weixin.qq.com/s/bo1si3B0kHwI4MjEODmtUw' target="_blank">详情请点击此处</a>
          </p>
          <span style={{ float: "right", marginRight: 0 }}>上海众调信息科技有限公司</span><br />
          <span style={{ float: "right", marginRight: 0 }}> 2024年5月11日</span>
        </div>

        ,
      });
      setTimeout(() => {
        modal.destroy();
        sessionStorage.setItem("first", "1")
      }, 10000 * 1000);
    }
  }, [])
  // 当前banner图
  const [currentBanner, setCurrentBanner] = useState(0)

  // 切换banner
  const changeBanner = (current) => {
    console.log(current)
    setCurrentBanner(current)
  }

  // 显示的tab
  const [tabkey, setTabkey] = useState('cars')

  /** 滚动速度，值越小，滚动越快 */
  const speed = 30
  const warper1 = useRef()
  const warper2 = useRef()
  const warper3 = useRef()
  /** 原数据 */
  const childDom1 = useRef()
  /** 拷贝数据 */
  const childDom2 = useRef()
  const childDom3 = useRef()
  const childDom4 = useRef()
  const childDom5 = useRef()
  const childDom6 = useRef()
  // 开始滚动
  useEffect(() => {
    // 多拷贝一层，让它无缝滚动
    childDom2.current.innerHTML = childDom1.current.innerHTML
    childDom4.current.innerHTML = childDom3.current.innerHTML
    childDom6.current.innerHTML = childDom5.current.innerHTML
    warper2.current.scrollLeft = childDom3.current.scrollWidth
    let timer
    timer = setInterval(() => {
      warper1.current.scrollLeft >= childDom1.current.scrollWidth
        ? (warper1.current.scrollLeft = 0)
        : warper1.current.scrollLeft++

      warper2.current.scrollLeft === 0
        ? (warper2.current.scrollLeft = childDom3.current.scrollWidth)
        : warper2.current.scrollLeft--

      warper3.current.scrollLeft >= childDom5.current.scrollWidth
        ? (warper3.current.scrollLeft = 0)
        : warper3.current.scrollLeft++
    }, speed)
    return () => {
      clearTimeout(timer)
    }
  }, [tabkey])

  const changePane = (pane) => {
    setTabkey(pane)
  }

  return (
    <div className="home">
      {/* banner */}
      <Carousel
        autoplay
        afterChange={changeBanner}
        autoplaySpeed={7000}
        arrows={true}
        prevArrow={<LeftOutlined />}
        nextArrow={<RightOutlined />}
      >
        <div className="home-banner">
          <div
            className="banner-pic"
            style={{ backgroundImage: `url(${bannerdong})` }}
          ></div>
          <div className="banner-content">
            <span className="banner-title">数据中台</span>
            <span className="banner-subtitle">
              人车全生命周期价值数智赋能平台
            </span>
            <span className="banner-des">
              [ 数据采集 ] [ 实时数仓 ] [ 用户画像 ] [ 标签管理 ] [ 算法模型 ] [
              报表分析 ]
            </span>
            <div className="banner-tab">
              <div className="tab-top">
                <div>
                  <span>CDP</span>
                  <span>用户数据中台</span>
                </div>
                <div>
                  <span>VDP</span>
                  <span>车联数据中台</span>
                </div>
                <div>
                  <span>NDP</span>
                  <span>网络数据中台</span>
                </div>
                <div>
                  <span>MDP</span>
                  <span>内容数据中台</span>
                </div>
              </div>
              <div className="tab-bottom">
                <div>
                  <span>UDDP</span>
                  <span>数字资产平台</span>
                </div>
                <div>
                  <span>UMAAS</span>
                  <span>通用模型中台</span>
                </div>
                <div>
                  <span>MA</span>
                  <span>营销自动化</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-banner">

          <div
            className={
              currentBanner === 1 ? 'big-banner banner-pic' : 'banner-pic'
            }
            style={{ backgroundImage: `url(${banner2})` }}
          ></div>

          <div className="banner-content">
            <span className="banner-title">成交引擎</span>
            <span className="banner-subtitle">
              大数据ai算法赋能门店智能运营平台，提升客户体验与营销转化
            </span>
            <span className="banner-des">
              [ 语音识别技术 ] [ 自然语义识别 ] [ 会话智能 ]
              <br />[ 推荐算法 ] [ 知识图谱 ] [ AI-DCC ] [ 线索评级 ] [ AI试驾 ]
              [ 售后听鉴 ] [ 流失挽回 ] [ 客户价值模型 ]
            </span>
          </div>
        </div>
        <div className="home-banner">

          <div
            className={
              currentBanner === 2 ? 'big-banner banner-pic' : 'banner-pic'
            }
            style={{ backgroundImage: `url(${banner3})` }}
          ></div>

          <div className="banner-content">
            <span className="banner-title">直售引擎</span>
            <span className="banner-subtitle">
              客户全生命周期运营与价值创造，首创面向车型的直售运营模式
              <br />
              打造官方直售标杆
            </span>
            <span className="banner-des">
              [ 新能源汽车直售平台 ] [ 官方二手车直售平台 ] [ 新能源汽车直售运营
              ] [ 汽车元宇宙销售展厅 ]
            </span>
          </div>
        </div>
        <div className="home-banner">

          <div
            className='banner-pic'
            style={{ backgroundImage: `url(${banner})` }}
          ></div>

          <div className="banner-content">
            <span className="banner-title"> AI中台</span>
            <span className="banner-subtitle">
              车企数智化能力基座平台<br />
              集创造、管理、展示、应用AI能力的一站式企业级AI平台<br />
              助力车企迅速建立数字化中台体系，实现AI在汽车价值链的快速落地
            </span>
          </div>
        </div>
        <div className="home-banner">

          <div
            className={
              currentBanner === 4 ? 'big-banner banner-pic' : 'banner-pic'
            }
            style={{ backgroundImage: `url(${bg1})` }}
          ></div>

          <div className="banner-content">
            <span className="banner-title">标注平台</span>
            <span className="banner-subtitle">
              是一款智能化数据标注平台，支持图像、文本、视频、音频等多种数据类型的标注
            </span>
          </div>
        </div>
      </Carousel>
      {/* 简介 */}
      <div className="home-intro">
        <div className="banner-content">
          <Fade bottom>
            <div className="banner-title">万物皆可数据，一切皆可智能</div>
          </Fade>
          <Fade bottom>
            <span className="intro-subtitle">
              <span className="intro-subtitle-icon"></span>
              成为一家懂汽车消费者，懂智能汽车的企业
              <span className="intro-subtitle-icon"></span>
            </span>
          </Fade>
          <Fade bottom>
            <span className="banner-des">
              以大数据为核心，人工智能为引擎，打造数据智能服务平台驱动汽车行业数字化转型
              <br />
              聚焦营销科技、车联研发、自动驾驶，赋能出行生态，引领汽车消费
            </span>
          </Fade>
          <Fade bottom>
            <div className="modal-list">
              <div className="modal-list-in">
                <div className="modal-list-in-icon"></div>
                <div className="modal-list-in-title">汽车数据中台</div>
                <div className="modal-list-in-context">
                  为汽车品牌实时集成多元数据源实现数据资产化，通过数据中台产品与智能算法平台，融通汽车领域客户、车辆、网点与内容数据，赋能全链路人车体验与营销效率提升。
                </div>
              </div>
              <div className="modal-list-in">
                <div className="modal-list-in-icon"></div>
                <div className="modal-list-in-title">算法模型平台</div>
                <div className="modal-list-in-context">
                  基于汽车行业技术智能与认知智能，持续构建行业级的标签与模型库，通过U-Drive平台，将算法与模型能力组件化与可视化，赋能汽车营销、研发智造与自动驾驶领域。
                </div>
              </div>
              <div className="modal-list-in">
                <div className="modal-list-in-icon"></div>
                <div className="modal-list-in-title">智能场景赋能</div>
                <div className="modal-list-in-context">
                  数据与模型能力输出到场景应用，包括会员运营与门店智能等，典型应用在AI-DCC、试乘试驾、潜客运营与售后接待等，通过ASR+NLP等技术实现过程质检与用户画像。   </div>
              </div>
              <div className="modal-list-in">
                <div className="modal-list-in-icon"></div>
                <div className="modal-list-in-title">数据运营服务</div>
                <div className="modal-list-in-context">
                  以效果为导向的，通过自研数据产品+实施辅导的方式助力客户进行数据探查、数据研究与数据赋能，典型案例可提升140%邀约成功率、100%的试驾转化率与80%的订单量。
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      {/* 行业合作 */}
      <div className="home-cooper">
        <Fade bottom>
          <span className="cooper-title">行业合作 & 服务伙伴</span>
        </Fade>
        <Tabs defaultActiveKey="cars" centered onChange={changePane}>
          <TabPane tab="汽车厂商" key="cars">
            {tabkey === 'cars' ? (
              <>
                <div className="cooper-parent" ref={warper1}>
                  <div className="cooper-child" ref={childDom1}>
                    <img src={logo1} alt="logo"></img>
                    <img src={logo2} alt="logo"></img>
                    <img src={logo3} alt="logo"></img>
                    <img src={logo4} alt="logo"></img>
                    <img src={logo5} alt="logo"></img>
                  </div>
                  <div className="cooper-child" ref={childDom2}></div>
                </div>

                <div className="cooper-parent" ref={warper2}>
                  <div className="cooper-child" ref={childDom3}>
                    <img src={logo6} alt="logo"></img>
                    <img src={logo7} alt="logo"></img>
                    <img src={logo8} alt="logo"></img>
                    <img src={logo9} alt="logo"></img>
                  </div>
                  <div className="cooper-child" ref={childDom4}></div>
                </div>

                <div className="cooper-parent" ref={warper3}>
                  <div className="cooper-child" ref={childDom5}>
                    <img src={logo10} alt="logo"></img>
                    <img src={logo11} alt="logo"></img>
                    <img src={logo12} alt="logo"></img>
                    <img src={logo13} alt="logo"></img>
                    <img src={logo14} alt="logo"></img>
                  </div>
                  <div className="cooper-child" ref={childDom6}></div>
                </div>
              </>
            ) : (
              ''
            )}
          </TabPane>
          <TabPane tab="行业合作伙伴" key="partner">
            {tabkey === 'partner' ? (
              <>
                <div className="cooper-parent" ref={warper1}>
                  <div className="cooper-child" ref={childDom1}>
                    <img src={partner1} alt="logo"></img>
                    <img src={partner2} alt="logo"></img>
                    <img src={partner3} alt="logo"></img>
                    <img src={partner4} alt="logo"></img>
                    <img src={partner5} alt="logo"></img>
                  </div>
                  <div className="cooper-child" ref={childDom2}></div>
                </div>

                <div className="cooper-parent" ref={warper2}>
                  <div className="cooper-child" ref={childDom3}>
                    <img src={partner6} alt="logo"></img>
                    <img src={partner7} alt="logo"></img>
                    <img src={partner8} alt="logo"></img>
                    <img src={partner9} alt="logo"></img>
                  </div>
                  <div className="cooper-child" ref={childDom4}></div>
                </div>

                <div className="cooper-parent" ref={warper3}>
                  <div className="cooper-child" ref={childDom5}>
                    <img src={partner10} alt="logo"></img>
                    <img src={partner11} alt="logo"></img>
                    <img src={partner12} alt="logo"></img>
                    <img src={partner13} alt="logo"></img>
                    <img src={partner14} alt="logo"></img>
                  </div>
                  <div className="cooper-child" ref={childDom6}></div>
                </div>
              </>
            ) : (
              ''
            )}
          </TabPane>
          <TabPane tab="高校研究机构" key="school">
            {tabkey === 'school' ? (
              <>
                <div className="cooper-parent" ref={warper1}>
                  <div className="cooper-child" ref={childDom1}>
                    <img src={school1} alt="logo"></img>
                    <img src={school2} alt="logo"></img>
                    <img src={school3} alt="logo"></img>
                    <img src={school4} alt="logo"></img>
                    <img src={school5} alt="logo"></img>
                  </div>
                  <div className="cooper-child" ref={childDom2}></div>
                </div>

                <div className="cooper-parent" ref={warper2}>
                  <div className="cooper-child" ref={childDom3}></div>
                  <div className="cooper-child" ref={childDom4}></div>
                </div>
                <div className="cooper-parent" ref={warper3}>
                  <div className="cooper-child" ref={childDom5}></div>
                  <div className="cooper-child" ref={childDom6}></div>
                </div>
              </>
            ) : (
              ''
            )}
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default Home
