import './index.less'
import 'swiper/less';
import React, { Component } from 'react'
import { Tabs } from 'antd'
import introduction from '@/assets/ecologyjj.png'
import CoState1 from '@/assets/ecologypeitu_1.png'
import peitu4 from '@/assets/peitu_4.png'
import peitu5 from '@/assets/peitu_5.png'
import peitu6 from '@/assets/peitu_6.png'
import peitu7 from '@/assets/ecologybg.png'
import teamImg1 from '@/assets/tuandui1.png'
import teamImg2 from '@/assets/tuandui2.png'
import teamImg3 from '@/assets/tuandui3.png'
import teamImg4 from '@/assets/tuandui4.png'
import teamImg5 from '@/assets/zj-5.jpg'
import teamImg6 from '@/assets/zj-6.jpg'
import ecologybg1 from '@/assets/ecologybg1.png'
import Fade from 'react-reveal/Fade'
import { Swiper, SwiperSlide } from 'swiper/react'


export default class MobileEcology extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const dataList = [
      {
        title: '公司简介',
        href: "#introduction",
      },
      {
        title: '公司动态',
        href: "#action"
      },
      {
        title: '研究报告',
        href: "#report"
      },
      {
        title: '专家团队',
        href: "#team"
      },
      {
        title: '众调荣誉',
        href: "#honor"
      }
    ]
    return (
      <div className="mobile-ecology">
        <div className="banner">
          <img src={ecologybg1} alt=''></img>
          <Fade bottom>
            <span className="banner-title">
              公司生态
            </span>
            <div className="data-list">
              <Tabs>
                {
                  dataList.map((item, index) => {
                    return (
                      <Tabs.TabPane tab={
                        <a className='banner-tabs' href={item.href}>
                          <div>
                            {item.title}
                          </div>
                        </a>} key={index}>
                      </Tabs.TabPane>
                    )
                  })
                }
              </Tabs>
            </div>
          </Fade>
        </div>
        <div className="introduction" id='introduction'>
          <Fade bottom><span className='title'>公司简介</span></Fade>
          <Fade bottom><img src={introduction} alt="/" /></Fade>
          <Fade bottom>
            <div className='graphy'>
              <span>
                众调科技成立于2015年5月25日，定位于以“一个大脑，两个引擎”为核心的大数据智能引擎服务商，
                基于中台驱动的营销大脑和产品+成交双引擎，从数据、产品、服务等三个层面展开深度融合，
                为车企数字化企业提供一体化智慧运营解决方案，帮助车企打通线上线下、链接前台后台、贯通私域公域。
                同时众调科技也为零售、保险、地产等行业提供咨询和数智化解决方案，现有业务包含数据中台、业务中台、AI中台、成交引擎、直售引擎等。
              </span>
              <span>
                目前，众调科技已为国内外半数车企提供服务，
                在销售、出行、金融等多方面为企业赋能。
                同时，众调科技在人工智能 + 数据技术应用方面也沉淀了行业领先的丰富实践经验，
                并将此成功经验复制至其他行业领域进行应用。经过多年在大数据行业的深耕，
                已成为上海市科技小巨人培育企业，荣获高新技术及双软企业、专精特精企业，同时加入阿里云服务生态伙伴。
              </span>
            </div>
          </Fade>
        </div>
        <div id="action">
          <span className='title'>公司动态</span>
          <Swiper
            centeredSlides={true}
            slidesPerView={1.4}
            initialSlide={2}
            loop={true} // 是否循环
          >
            <SwiperSlide>
              <a className='action-item' href='https://mp.weixin.qq.com/s/G7-utGp36opKwKRnLQmBvA'>
                <img src={CoState1} alt="/" />
                <div className='action-item-bottom'>
                  <span className='action-item-title'>
                    喜报|众调科技荣获上海市“专精...
                  </span>
                  <span className='action-item-cont'>
                    引导企业走“专精特新”发展之路，是党中央、国务院促进小企业高质量发展的一项重要举措，也是科技强国...
                  </span>
                  <div className='action-item-time'>
                    <span>2022-02-22 18:00</span>
                    <span>众调汽车大数据</span>
                  </div>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a className='action-item' href='https://www.sohu.com/a/139329112_501677'>
                <img src={peitu4} alt="" />
                <div className='action-item-bottom'>
                  <span className='action-item-title'>
                    众调科技强强联手普华永道…
                  </span>
                  <span className='action-item-cont'>
                    近日，普华永道（中国）与众调科技正式签署战略合作协议。本次战略合作针对双方在汽车行业的资源...
                  </span>
                  <div className='action-item-time'>
                    <span>2022-02-22 18:00</span>
                    <span>众调汽车大数据</span>
                  </div>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a className='action-item' href='https://mp.weixin.qq.com/s/XeGug1cflOesxrvb1jKAYQ'>
                <img src={peitu5} alt="" />
                <div className='action-item-bottom'>
                  <span className='action-item-title'>
                    数据中台打破数据…
                  </span>
                  <span className='action-item-cont'>
                    经公开自主申报、专家评审等程序，上海市科学技术委员会于2021年9月18日公布2021年度上海市…
                  </span>
                  <div className='action-item-time'>
                    <span>2022-02-22 18:00</span>
                    <span>众调汽车大数据</span>
                  </div>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a className='action-item' href='https://mp.weixin.qq.com/s/XeGug1cflOesxrvb1jKAYQ'>
                <img src={peitu6} alt="" />
                <div className='action-item-bottom'>
                  <span className='action-item-title'>
                    威马汽车携手众调科技…
                  </span>
                  <span className='action-item-cont'>
                    1月16日，威马汽车发布“威马直购”新零售模式。“威马直购”以“半价提车、出行合约套餐...
                  </span>
                  <div className='action-item-time'>
                    <span>2022-02-22 18:00</span>
                    <span>众调汽车大数据</span>
                  </div>
                </div>
              </a>
            </SwiperSlide>
          </Swiper>
        </div>

        <div id="report">
          <Fade bottom>
            <span className='title'>研究报告</span>
          </Fade>
          <Fade bottom>
            <img src={peitu7} alt="" />
          </Fade>

          <Fade bottom>
            <div className='report-content'>
              <a href='https://mp.weixin.qq.com/s/FoZNON9Hixeda9XcnCFjZA'>
                <span>2022年5月分析报告</span>
                <span>&gt;</span>
              </a>
              <a href='https://mp.weixin.qq.com/s/syyPopojbxAPbsAvqHovAw'>
                <span>2022年4月分析报告</span>
                <span>&gt;</span>
              </a>
              <a href='https://mp.weixin.qq.com/s/OhpuYjMC5eGiBwqmgTD49g'>
                <span>2022年3月分析报告</span>
                <span>&gt;</span>
              </a>
              <a href='https://mp.weixin.qq.com/s/Kr9_r0YM8r2Wc79zeB_Q9A'>
                <span>2022年2月分析报告</span>
                <span>&gt;</span>
              </a>
              <a href='https://mp.weixin.qq.com/s/nRc88PUv1NvETCQieODsrA'>
                <span>2022年1月分析报告</span>
                <span>&gt;</span>
              </a>
              <a href='https://mp.weixin.qq.com/s/IAlsnugAIo5hL6e1UmTSgQ'>
                <span>2021年12月分析报告</span>
                <span>&gt;</span>
              </a>
            </div>
          </Fade>
        </div>
        <div id="team">
          <Fade bottom>
            <span className='title'>核心团队</span>
          </Fade>
          <Fade bottom>
            <div className='team-members'>
              <div className='member-item'>
                <div className='member-top'>
                  <img src={teamImg3} alt="" />
                  <div className='member-top-right'>
                    <div className='member-title'>
                      <span>叶明海</span>
                      <span>教授/博导</span>
                    </div>
                    <div className='member-info'>
                      <span>同济大学MBA教学中心主任</span>
                    </div>
                  </div>
                </div>
                <div className='member-bottom'>
                  中国著名汽车营销专家/旅德访问学者/众调科技首席顾问/上海科技创业中心创业导师
                </div>
              </div>
              <div className='member-item'>
                <div className='member-top'>
                  <img src={teamImg6} alt="" />
                  <div className='member-top-right'>
                    <div className='member-title'>
                      <span>凌鸿</span>
                    </div>
                    <div className='member-info'>
                      <span>复旦大学管理学院教授，信息管理系主任</span>
                    </div>
                  </div>
                </div>
                <div className='member-bottom'>
                  美国麻省理工学院访问学者，信息系统协会中国分会理事会理事。上海集优机械股份有限公司独立董事。
                </div>
              </div>
              <div className='member-item'>
                <div className='member-top'>
                  <img src={teamImg1} alt="" />
                  <div className='member-top-right'>
                    <div className='member-title'>
                      <span>郑鑫</span>
                      <span>众调科技董事长</span>
                    </div>
                    <div className='member-info'>
                      <span>同济大学 博士 教授</span>
                      <span>美国南加州大学 访问学者</span>
                      {/* <span>汽车营销和大数据专家</span> */}
                    </div>
                  </div>
                </div>
                <div className='member-bottom'>
                  同济大学-IBM大数据技术及分析中心执行主任，同济大学-享道出行移动大数据人工智能实验室执行主任，中关村大数据产业联盟汽车专委会主任，20年汽车产业经验，连续创业者。
                </div>
              </div>
              <div className='member-item'>
                <div className='member-top'>
                  <img src={teamImg4} alt="" />
                  <div className='member-top-right'>
                    <div className='member-title'>
                      <span>赵国栋</span>
                    </div>
                    <div className='member-info'>
                      <span>中关村大数据产业联盟秘书长</span>
                    </div>
                  </div>
                </div>
                <div className='member-bottom'>
                  中国计算机学会大数据专家委员会委员/北京邮电大学经济管理学院MBA特聘导师/盘古智库学术
                </div>
              </div>
              <div className='member-item'>
                <div className='member-top'>
                  <img src={teamImg5} alt="" />
                  <div className='member-top-right'>
                    <div className='member-title'>
                      <span>林倞</span>
                    </div>
                    <div className='member-info'>
                      <span>中山大学数据科学与计算机学院教授</span>
                    </div>
                  </div>
                </div>
                <div className='member-bottom'>
                  国家万人计划青年拔尖人才，国家优秀青年基金获得者，教育部超算工程软件工程研究中心副主任，IET Fellow。商汤科技执行研发总监/研究院副院长/杰出科学家。
                </div>
              </div>
              <div className='member-item'>
                <div className='member-top'>
                  <img src={teamImg2} alt="" />
                  <div className='member-top-right'>
                    <div className='member-title'>
                      <span>谢鹏</span>
                      <span>众调科技CEO</span>
                    </div>
                    <div className='member-info'>
                      <span>中关村大数据产业联盟汽车专委会秘书长</span>
                    </div>
                  </div>
                </div>
                <div className='member-bottom'>
                  复旦MBA，16年互联网及创新管理经验，曾就职多家国内外知名企业，具有丰富的互联网/大数据技术与管理经验。
                </div>
              </div>

            </div>
          </Fade>
        </div>
        <div id='honor'>
          <div className="ecology-title">
            众调荣誉
          </div>
          <Fade bottom>
            <ul className="honorList">
              <li>· 上海市高新技术企业</li>
              <li>· 上海市科技小巨人孵化企业</li>
              <li>· 汽车大数据专业委员会主任单位</li>
              <li>· 戴姆勒Star Autobahn最佳精准奖</li>
              <li>· 信息服务业新业态创新企业30强</li>
              <li>· 中国大数据行业应用Top Choice</li>
              <li>· 上海市专精特新企业</li>
              <li>· 双软企业</li>
              <li>· 邓白氏注册认证企业</li>
              <li>· 中关村大数据产业联盟优秀大数据企业</li>
              <li>· 中国汽车互联网+创新100强</li>
              <li>· 十佳数字化转型解决方案项目</li>
            </ul>
          </Fade>
        </div>
      </div>
    )
  }
}
