import React, { useCallback, useEffect, useState } from 'react'
import { Tabs } from 'antd';
import databg1 from '@/assets/databg1.png'
import syllsj from '@/assets/syllsj.png'
import jt from '@/assets/jt.png'
import jw from '@/assets/jw.png'
import bqhx from '@/assets/bqhx.png'
import ksh from '@/assets/ksh.png'
import sjzt from '@/assets/sjzt.png'
import yfsj from '@/assets/yfsj.png'
import yxzdh from '@/assets/yxzdh.png'
import pp from '@/assets/pp.png'
import xfz from '@/assets/xfz.png'
import szhty from '@/assets/szhty.png'
import yxzx from '@/assets/yxzx.png'
import mdgj from '@/assets/mdgj.png'
import yxybmd from '@/assets/yxybmd.png'
import cxgs from '@/assets/cxgs.png'
import wxgs from '@/assets/wxgs.png'
import escgs from '@/assets/escgs.png'
import sjgs from '@/assets/sjgs.png'
import sfgs from '@/assets/sfgs.png'
import wbgs from '@/assets/wbgs.png'
import CDP from '@/assets/CDP.png'
import VDP from '@/assets/VDP.png'
import NDP from '@/assets/NDP.png'
import MDP from '@/assets/MDP.png'
import UDDP from '@/assets/UDDP.png'
import UMAAS from '@/assets/UMAAS.png'
import MA from '@/assets/MA.png'
import icon from '@/assets/zs.png'
import Fade from 'react-reveal/Fade'
import Slide from 'react-reveal/Fade'
import './index.less'
const dataList = [
  {
    title: 'CDP',
    content: '用户数据中台',
    href: "#user"
  },
  {
    title: 'VDP',
    content: '车联数据中台',
    href: "#car"
  },
  {
    title: 'NDP',
    content: '网络数据中台',
    href: "#network"
  },
  {
    title: 'MDP',
    content: '内容数据中台',
    href: "#content"
  },
  {
    title: 'UDDP',
    content: '数字资产中台',
    href: "#number"
  },
  {
    title: 'UMAAS',
    content: '通用模型中台',
    href: "#umaas"
  },
  {
    title: 'MA',
    content: '营销自动化',
    href: "#ma"
  },
]
const MobileDataCenter = () => {
  const [itemTwoIsShow, setItemTwoIsShow] = useState(false)
  // 监听页面滚动位置
  const checkPosition = useCallback(() => {
    if (document.documentElement.scrollTop >= 300) {
      setItemTwoIsShow(true)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    //document.querySelector('.datacenter').scrollIntoView(true) //为ture返回顶部，false为底部
    window.addEventListener('scroll', checkPosition)
    return () => {
      window.removeEventListener('scroll', checkPosition)
    }
  }, [itemTwoIsShow, checkPosition])

  return (
    <div className="mobile-datacenter">
      <div className="item-1">
        <img src={databg1} alt=''></img>
        <Fade bottom>
          <div className='title'>数据中台解决方案</div>
          <span>人车全生命周期价值</span>
          <span>数智赋能平台</span>
          <span>基于客户的</span>
          <span>全域营销用户数据中台</span>
          <span>做汽车用户的</span>
          <span>生命周期的个性化服务</span>
          <span>打造美好车生活</span>

          <div className="data-list">
            <Tabs>
              {
                dataList.map((item, index) => {
                  return (
                    <Tabs.TabPane tab={
                      <a className='banner-tabs' href={item.href}>
                        <div>
                          {item.title}
                          <br />
                          {item.content}
                        </div>
                      </a>} key={index}>
                    </Tabs.TabPane>
                  )
                })
              }
            </Tabs>
          </div>
        </Fade>
      </div>
      <div className="item-2">
        <div className='title'>数据中台</div>
        <div className="postion">
          <Slide left>
            <div className="banner-item syllsj">
              <img src={syllsj} alt="" />
              <ul>
                <li>官方网站</li>
                <li>微信公众号</li>
                <li>小程序</li>
                <li>APP</li>
              </ul>
            </div>
            <div className="banner-item bqhx">
              <img src={bqhx} alt="" />
              <ul>
                <li>标签服务</li>
                <li>画像服务</li>
                <li>算法模型</li>
              </ul>
            </div>
            <div className="banner-item yfsj">
              <img src={yfsj} alt="" />
              <ul>
                <li>用户信息</li>
                <li>车展活动</li>
                <li>售后服务</li>
                <li>车联网</li>
                <li>智能客服</li>
                <li>经销商</li>
              </ul>
            </div>
          </Slide>

          <Slide left>
            <div className="banner-item sjzt">
              <img src={sjzt} alt="" />
              <ul>
                <li>数据资产管理</li>
                <li>用户数据分析</li>
                <li>数据开发与治理</li>
              </ul>
            </div>
          </Slide>

          <Slide left>
            <div className="banner-item yxzdh">
              <img src={yxzdh} alt="" />
              <ul>
                <li>行为埋点</li>
                <li>API 接口</li>
                <li>全域智能营销</li>
              </ul>
            </div>

            <div className="banner-item ksh">
              <img src={ksh} alt="" />
              <ul>
                <li>核心指标</li>
                <li>转化漏斗</li>
                <li>业务大屏</li>
              </ul>
            </div>

            <div className="banner-item jw">
              <img src={jw} alt="" />
              <ul>
                <li>CDP</li>
                <li>VDP</li>
                <li>MDP</li>
                <li>NDP</li>
              </ul>
            </div>
          </Slide>

          <Fade left>
            <img src={jt} alt="" className="jt jt1" />
            <img src={jt} alt="" className="jt jt2" />
            <img src={jt} alt="" className="jt jt3" />
            <img src={jt} alt="" className="jt jt4" />
            <img src={jt} alt="" className="jt jt5" />
            <img src={jt} alt="" className="jt jt6" />
            <img src={jt} alt="" className="jt jt7" />
          </Fade>
        </div>
      </div>

      <div className="item-3">
        <div className='title'>数智赋能平台</div>
        <div className='data-item'>
          <div className='data-item-title'>消费者体验智能化</div>
          <div className="hengxian"></div>
          <div className="data-item-list">
            <div className="item">
              <img src={pp} alt="" /> <span>某汽车品牌</span>
            </div>
            <div className="item">
              <img src={xfz} alt="" /> <span>消费者</span>
            </div>
            <div className="item">
              <img src={szhty} alt="" /> <span>数智化体验</span>
            </div>
          </div>
        </div>
        <div className='data-item'>
          <div className='data-item-title'>车企内部数智化</div>
          <div className="hengxian"></div>
          <div className="data-item-list">
            <div className="item">
              <img src={yxzx} alt="" /> <span>营销中心</span>
            </div>
            <div className="item">
              <img src={mdgj} alt="" /> <span>门店工具</span>
            </div>
            <div className="item">
              <img src={yxybmd} alt="" /> <span>营销样板门店</span>
            </div>
          </div>
        </div>
        <div className='data-item'>
          <div className='data-item-title'>行业生态赋能</div>
          <div className="hengxian"></div>
          <div className="data-item-list industry-list">
            <div className="item">
              <img src={cxgs} alt="" /> <span>车险公司</span>
            </div>
            <div className="item">
              <img src={wxgs} alt="" /> <span>维修公司</span>
            </div>
            <div className="item">
              <img src={escgs} alt="" /> <span>二手车公司</span>
            </div>
            <div className="item">
              <img src={sjgs} alt="" /> <span>数据公司</span>
            </div>
            <div className="item">
              <img src={sfgs} alt="" /> <span>算法公司</span>
            </div>
            <div className="item">
              <img src={wbgs} alt="" /> <span>外包公司</span>
            </div>
          </div>
        </div>
        <div className="type">
          <Slide bottom>
            <div className='type-item'>
              <div className="item">
                <div className="text">CDP</div>
              </div>
              <p>客户意向评估与个性推荐、用户画像与再购用户激活</p>
              <p>客户流失预测及挽回营销、客户产品偏好与推广策略</p>
            </div>
            <div className='type-item'>
              <div className="item">
                <div className="text">VDP</div>
              </div>
              <p>车辆定价与估值、车辆产品规划、电池健康度</p>
              <p>资产管理、车辆运营数据、车辆驾驶行为</p>
            </div>
            <div className='type-item'>
              <div className="item">
                <div className="text">NDP</div>
              </div>
              <p>网络健康度评级、4S/商圈/桩选址</p>
              <p>网络数据监测、在线培训系统</p>
            </div>
            <div className='type-item'>
              <div className="item">
                <div className="text">MDP</div>
              </div>
              <p>智能推荐、内容生产、内容运营</p>
              <p>尊崇交互、画像标签</p>
            </div>
            <div className='type-item'>
              <div className="item">
                <div className="text translate-1">UDDP</div>
              </div>
              <p>数据集成、数据治理、数据资产规划</p>
              <p>数据资产评估、数据资产应用</p>
            </div>
            <div className='type-item'>
              <div className="item">
                <div className="text translate-2">UMAAS</div>
              </div>
              <p>AI资产、模型集市、多维建模、模型实时训练</p>
              <p>模型部署、模型监控、模型分析</p>
            </div>
            <div className='type-item'>
              <div className="item">
                <div className="text ">MA</div>
              </div>
              <p>营销策略分析、营销策略画布、策略任务工作流</p>
              <p>元数据管理、AB测试、渠道管理、系统管理</p>
            </div>
          </Slide>
        </div>
      </div>

      <div className="item-4">
        {/* CDP全域营销数据中台 */}
        <div className='item-4-item' id='user'>
          <Slide bottom>
            <img src={CDP} alt="" />
          </Slide>
          <Slide bottom>
            <div className='item-4-title'>
              <img src={icon} alt=''></img>
              <span>CDP全域营销数据中台</span>
            </div>
            <div className="item">
              <span>核心模块</span>
              <span>
                客户画像标签、营销自动化、产品服务匹配、活动管理、网络选址、销量预测等。
              </span>
            </div>
            <div className="item">
              <span>产品定位</span>
              <span>
                围绕“人-车”生命周期轨迹的全面触点数据（车辆行为、网络行为、消费行为、社交媒体等大规模数据），整合汽车销售主体
                （主机厂/经销商集团/汽车电商/服务连锁）的内部场景数据与外部行为数据，建立基于行业认知的算法模型，
                使用大数据技术分析并预测客户行为，获取洞察并指导业务行动，监测跟踪行动结果并进行持续迭代优化。
                全面挖掘客户价值形成360度标签体系和客户洞察，通过客户标签系统、车主/潜客消费地图、网点选择、产品/价格偏好等，
                为销售主体提供客户“选、购、用、养、换”全生命周期多业务场景的智能产品推荐、营销活动组织与客户体验提升。
              </span>
            </div>
            <div className="item">
              <span>服务对象</span>
              <span>
                汽车主机厂、经销商集团、汽车销售连锁、汽车电商公司、保险公司、金融公司等。
              </span>
            </div>
          </Slide>
        </div>
        {/* VDP车辆数据中台 */}
        <div className='item-4-item' id='car'>
          <Slide bottom>
            <img src={VDP} alt="" />
          </Slide>

          <Slide bottom>
            <div className='item-4-title'>
              <img src={icon} alt=''></img>
              <span>VDP车辆数据中台</span>
            </div>
            <div className="item">
              <span>核心模块</span>
              <span>
                车辆数据采集、新能源汽车电池健康度监测、残值动态、残值预测、车辆估值、
                <br />
                残值影响因素、残值报告、保值营销等。
              </span>
            </div>
            <div className="item">
              <span>产品定位</span>
              <span>
                针对车辆数据动态变化，为主机厂、保险公司、金融公司、融资租赁公司提供车辆相关精准数据及算法模型，
                通过对车辆大数据的监测及预测，获取对车辆生产、设计、定价、服务提升、产品升级、残值预测等多场景化的应用，
                与CDP可形成完整的标签体系和产品及客户洞察。
              </span>
            </div>
            <div className="item">
              <span>服务对象</span>
              <span>
                汽车主机厂、经销商、融资租赁公司、保险公司、金融公司等。
              </span>
            </div>
          </Slide>
        </div>
        {/* NDP数据驱动的智能网络分析决策平台 */}
        <div className='item-4-item' id='network'>
          <Slide bottom>
            <img src={NDP} alt="" />
          </Slide>
          <Slide bottom>
            <div className='item-4-title'>
              <img src={icon} alt=''></img>
              <span>NDP数据驱动的智能网络分析决策平台</span>
            </div>
            <div className="item">
              <span>核心模块</span>
              <span>网络规划、商圈选址、点位推荐、电桩选址等。</span>
            </div>
            <div className="item">
              <span>产品定位</span>
              <span>
                针对营销网络动态变化，为主机厂提供整体智慧网络分析决策服务，解决汽车销售主体（主机厂/经销商集团/汽车电商/服务连锁/充电桩）的痛点。
                众调科技通过政府数据、主机厂网络数据、结合本竞品网点情况数据、地区热力地图、历史上牌分布等，为主机厂制定网点规划策略、商圈选址、充电桩选址、
                经销商健康度监测等提供大数据智慧赋能。帮助主机厂在网络管理中提升工作能效，同时为网络规划决策提供强有力的数据支撑，确保网络布点的合理性与盈利能力。
              </span>
            </div>
            <div className="item">
              <span>服务对象</span>
              <span>
                汽车主机厂、经销商集团、汽车销售连锁、汽车电商公司、保险公司、金融公司等。
              </span>
            </div>
          </Slide>
        </div>
        {/* MDP内容数据中台 */}
        <div className='item-4-item' id='content'>
          <Slide bottom>
            <img src={MDP} alt="" />
          </Slide>
          <Slide bottom>
            <div className='item-4-title'>
              <img src={icon} alt=''></img>
              <span>MDP内容数据中台</span>
            </div>
            <div className="item">
              <span>核心模块</span>
              <span>
                智能推荐，内容生产，内容运营，尊崇交互，画像标签等。
              </span>
            </div>
            <div className="item">
              <span>产品定位</span>
              <span>
                针对营销网络动态变化，为主机厂提供整体智慧网络分析决策服务，解决汽车销售主体（主机厂/经销商集团/汽车电商/服务连锁/充电桩）的痛点。
                众调科技通过政府数据、主机厂网络数据、结合本竞品网点情况数据、地区热力地图、历史上牌分布等，为主机厂制定网点规划策略、商圈选址、充电桩选址、
                经销商健康度监测等提供大数据智慧赋能。帮助主机厂在网络管理中提升工作能效，同时为网络规划决策提供强有力的数据支撑，确保网络布点的合理性与盈利能力。
              </span>
            </div>
            <div className="item">
              <span>服务对象</span>
              <span>
                汽车主机厂、经销商集团、汽车销售连锁、汽车电商公司、保险公司、金融公司等。
              </span>
            </div>
          </Slide>
        </div>
        {/* UDDP */}
        <div className='item-4-item' id='number'>
          <Slide bottom>
            <img src={UDDP} alt="" />
          </Slide>
          <Slide bottom>
            <div className='item-4-title'>
              <img src={icon} alt=''></img>
              <span>UDDP数字资产中台</span>
            </div>
            <div>
              <div className="item">
                <span>核心模块</span>
                <span>
                  数据集成、数据治理、数据资产规划、数据资产评估、数据资产应用。
                </span>
              </div>
              <div className="item">
                <span>产品定位</span>
                <span>
                  众调数据资产管理平台（UnicData Data Platform）整合多源异构数据，通过可视化一站式的数据治理、数据加工、数据融合、与智能的数据资产规划能力。
                  将最有业务价值的数据通过合理、合规、安全、高效的方式提供给各业务条线的数据使用方，并全方面监控数据资产在全业务流程中赋能程度和成效。
                </span>
              </div>
              <div className="item">
                <span>服务对象</span>
                <span>汽车主机厂、经销商集团、汽车电商公司。</span>
              </div>
            </div>
          </Slide>
        </div>
        {/* UMAAS算法模型平台 */}
        <div className='item-4-item' id='umaas'>
          <Slide bottom>
            <img src={UMAAS} alt="" />
          </Slide>
          <Slide bottom>
            <div className='item-4-title'>
              <img src={icon} alt=''></img>
              <span>UMAAS算法模型平台</span>
            </div>
            <div className="item">
              <span>核心模块</span>
              <span>
                AI资产、模型集市、多维建模、模型实时训练、模型部署、模型监控、模型分析等。
              </span>
            </div>
            <div className="item">
              <span>产品定位</span>
              <span>
                UMaaS（UnicData Model As A Service）平台面向模型开发者和模型使用者，围绕模型创建、模型训练、模型效果分析、模型服务应用等一站式模型管理平台。
                用户简单操作即可完成模型的创建、部署等功能，改变了以往只有技术人员才能建模的状况，为用户提供了高效能的AI算法建模平台。
              </span>
            </div>
            <div className="item">
              <span>服务对象</span>
              <span>
                汽车主机厂、经销商集团、汽车买家、汽车电商公司、保险公司、金融公司等。
              </span>
            </div>
          </Slide>
        </div>
        {/* MA营销平台 */}
        <div className='item-4-item' id='ma'>
          <Slide bottom>
            <img src={MA} alt="" />
          </Slide>
          <Slide bottom>
            <div className='item-4-title'>
              <img src={icon} alt=''></img>
              <span>MA营销自动化</span>
            </div>
            <div className="item">
              <span>核心模块</span>
              <span>
                数据集成、元数据管理、营销策略画布、策略任务工作流、营销策略分析、AB测
                <br />
                试、渠道管理、系统管理。
              </span>
            </div>
            <div className="item">
              <span>产品定位</span>
              <span>
                为应对客户获取，客户维护和转化，客户忠诚度渠道数字化和多样化浪潮以及新的媒介类型层出不穷的趋势，
                营销策略运营平台（MA）通过提供业务领域数据整合能力，并提供元数据管理，以此为基础让营销和运营人员能够根据不同场景，
                不同的用户画像，实现触达方式和触达内容的千人千面，并且全过程可定制，可监控，可复盘，
                更重要的是能够借用无代码（No Code）模式实现全自动化的客户旅程的维系和触达，
                不仅极大降低了业务实现的周期，大大提高了营销和运营人员的效率，更重要的是提高了整体对客户目标达成的效果。
              </span>
            </div>
            <div className="item">
              <span>服务对象</span>
              <span>汽车主机厂、经销商集团、汽车电商公司。</span>
            </div>
          </Slide>
        </div>
      </div>
    </div>
  )
}

export default MobileDataCenter
