import React, { useEffect } from "react"
import './index.less'
const MobileMenu = (props) => {
    useEffect(() => {
        if (props.showMenu) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [props.showMenu])
    return (
        <div className="mobile-menu" style={{ display: props.showMenu ? "" : "none" }}>
            <div className="mobile-menu-left">
                <a href="/">首页</a>
                <a href="/datacenter" style={{ color: "#D98234" }}>解决方案</a>
                <a href="/case">成功案例</a>
                <a href="/academy">众调研究院</a>
                <a href="/ecology">公司生态</a>
            </div>
            <div className="mobile-menu-right">
                <div className="menu-right-title">
                    解决方案 &gt;
                </div>
                <div className="menu-right-subtitle">
                    <a href="/datacenter">数据中台</a>
                    <a href="/AIOpenPlatform">AI中台</a>
                    <a href="/dealengine">成交引擎</a>
                    <a href="/saleengine">直售引擎</a>
                    <a href="/annotationPlatform">标注平台</a>
                </div>
            </div>
        </div>
    )
}

export default MobileMenu
