import './index.less'
import navQp from '@/assets/nav-qp.png'
import gzh from '@/assets/gzh.jpg'
import mp from '@/assets/mp.png'
import { useCallback, useEffect, useState } from 'react'
import ewm from '@/assets/erweima.png'
import sjt from '@/assets/shangjiantou.png'
const MobileNavList = () => {
  const [wechat, setWechat] = useState(false)
  const [bc, setBc] = useState(false)
  const [showTop, setShowTop] = useState(false)

  // 监听页面滚动位置
  const checkPosition = useCallback(() => {
    setWechat(false)
    setBc(false)
    if(document.documentElement.scrollTop >= 500 || document.body.scrollTop >= 500) {
      setShowTop(true)
    }else {
      setShowTop(false)
    }
  }, [showTop])
 
  const handleWindowCilck = ()=> {
    setWechat(false)
    setBc(false)
  }
  useEffect(() => {
    window.addEventListener('scroll', checkPosition)
    window.addEventListener('click', handleWindowCilck)
    return () => {
      window.removeEventListener('scroll', checkPosition)
      window.removeEventListener('click', handleWindowCilck)
    }
  }, [showTop])
  return (
    <div className="mobile-nav-list">
      {showTop && (
        <div
          className="nav-item-1"
          onClick={() => {
            document.documentElement.scrollTop = 0 
            document.body.scrollTop = 0
          }}
        >
          <img src={sjt} alt="" />
        </div>
      )}
      <div
        className="nav-item-2"
        onClick={(e)=> {
            e.nativeEvent.stopImmediatePropagation() 
            setWechat(!wechat)
            setBc(false)
        }}
      >
        <img src={ewm} alt="" className="tp" />
        <img
          src={navQp}
          alt=""
          className="qp"
          style={{ display: wechat ? 'block' : 'none' }}
        />
        <div className="pic" style={{ display: wechat ? 'block' : 'none' }}>
          <img src={gzh} alt="" />
          <p className="text">关注微信公众号</p>
        </div>
      </div>
      <div
        className="nav-item-3"
        onClick={(e)=> {
            e.nativeEvent.stopImmediatePropagation() 
            setBc(!bc)
            setWechat(false)
        }}
      >
        <img src={ewm} alt="" className="tp" />
        <img
          src={navQp}
          alt=""
          className="qp"
          style={{ display: bc ? 'block' : 'none' }}
        />
        <div className="pic" style={{ display: bc ? 'block' : 'none' }}>
          <img src={mp} alt="" />
          <p className="text">众调汽车大数据</p>
        </div>
      </div>
    </div>
  )
}

export default MobileNavList
