import {isMobile} from 'react-device-detect';
export const isPc = () => {
    // if (
    //   window.navigator.userAgent.match(
    //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    //   )
    // ) {
    //   return false // 移动端
    // } else {
    //   return true // PC端
    // }
    if (
      isMobile
    ) {
      return false // 移动端
    } else {
      return true // PC端
    }
}

