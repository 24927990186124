import React, { useCallback, useEffect, useState } from 'react'
import bg1 from '../../../assets/bg1.jpg'
import bg2 from '../../../assets/bg2.jpg'
import bg3 from '../../../assets/bg3.jpg'
import bg4 from '../../../assets/bg4.png'
import syllsj from '../../../assets/syllsj.png'
import jt from '../../../assets/jt.png'
import jw from '../../../assets/jw.png'
import bqhx from '../../../assets/bqhx.png'
import ksh from '../../../assets/ksh.png'
import sjzt from '../../../assets/sjzt.png'
import yfsj from '../../../assets/yfsj.png'
import yxzdh from '../../../assets/yxzdh.png'
import xk1 from '../../../assets/xk1.png'
import xk2 from '../../../assets/xk2.png'
import pp from '../../../assets/pp.png'
import xfz from '../../../assets/xfz.png'
import szhty from '../../../assets/szhty.png'
import yxzx from '../../../assets/yxzx.png'
import mdgj from '../../../assets/mdgj.png'
import yxybmd from '../../../assets/yxybmd.png'
import cxgs from '../../../assets/cxgs.png'
import wxgs from '../../../assets/wxgs.png'
import escgs from '../../../assets/escgs.png'
import sjgs from '../../../assets/sjgs.png'
import sfgs from '../../../assets/sfgs.png'
import wbgs from '../../../assets/wbgs.png'
import CDP from '../../../assets/CDP.png'
import VDP from '../../../assets/VDP.png'
import NDP from '../../../assets/NDP.png'
import MDP from '../../../assets/MDP.png'
import UDDP from '../../../assets/UDDP.png'
import UMAAS from '../../../assets/UMAAS.png'
import MA from '../../../assets/MA.png'
import Fade from 'react-reveal/Fade'
import Slide from 'react-reveal/Fade'
import './index.less'

const dataList = [
  {
    title: 'CDP',
    content: '用户数据中台',
  },
  {
    title: 'VDP',
    content: '车联数据中台',
  },
  {
    title: 'NDP',
    content: '网络数据中台',
  },
  {
    title: 'MDP',
    content: '内容数据中台',
  },
  {
    title: 'UDDP',
    content: '数字资产中台',
  },
  {
    title: 'UMAAS',
    content: '通用模型中台',
  },
  {
    title: 'MA',
    content: '营销自动化',
  },
]

const DataCenter = () => {
  const [active, setActive] = useState(null)
  const [itemTwoIsShow, setItemTwoIsShow] = useState(false)
  // 监听页面滚动位置
  const checkPosition = useCallback(() => {
    if (document.documentElement.scrollTop >= 500) {
      setItemTwoIsShow(true)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    //document.querySelector('.datacenter').scrollIntoView(true) //为ture返回顶部，false为底部
    window.addEventListener('scroll', checkPosition)
    return () => {
      window.removeEventListener('scroll', checkPosition)
    }
  }, [itemTwoIsShow, checkPosition])
  const aclick = (e) => {
    console.log(e, e.target.innerText)
  }
  return (
    <div className="datacenter">
      <div className="item-1">

        <img src={bg1} alt="" className="big-img" />


        <div className="postion">
          <Fade bottom>
            <h2>数据中台解决方案</h2>
            <div>人车全生命周期价值数智赋能平台</div>
            <div>
              基于客户的全域营销用户数据中台，做汽车用户的生命周期的个性化服务
            </div>
            <div>打造美好车生活</div>
          </Fade>
        </div>
      </div>

      <div className="item-2">
        <div className="data-list">
          {dataList.map((item, index) => (
            <div
              className={index === active ? 'active' : ''}
              key={'datacenter' + index}
              onMouseMove={() => {
                setActive(index)
              }}
              onMouseLeave={() => {
                setActive(null)
              }}
            >
              <a href={`#${item.title}`}>
                <h5>{item.title}</h5> <p>{item.content}</p>
              </a>

            </div>
          ))
          }
        </div>

        <img src={bg2} alt="" />


        <div className='item-h2'>
          <h2>数据中台</h2>
        </div>
        {itemTwoIsShow && (
          <div>
            <div className="postion">
              <Slide left>
                <div className="syllsj">
                  <img src={syllsj} alt="" />
                  <ul>
                    <li>官方网站</li>
                    <li>微信公众号</li>
                    <li>小程序</li>
                    <li>APP</li>
                  </ul>
                </div>
                <div className="bqhx">
                  <img src={bqhx} alt="" />
                  <ul>
                    <li>标签服务</li>
                    <li>画像服务</li>
                    <li>算法模型</li>
                  </ul>
                </div>
                <div className="yfsj">
                  <img src={yfsj} alt="" />
                  <ul>
                    <li>用户信息</li>
                    <li>车展活动</li>
                    <li>售后服务</li>
                    <li>车联网</li>
                    <li>智能客服</li>
                    <li>经销商</li>
                  </ul>
                </div>
              </Slide>

              <Slide left>
                <div className="sjzt">
                  <img src={sjzt} alt="" />
                  <ul>
                    <li>数据资产管理</li>
                    <li>用户数据分析</li>
                    <li>数据开发与治理</li>
                  </ul>
                </div>
              </Slide>

              <Slide left>
                <div className="yxzdh">
                  <img src={yxzdh} alt="" />
                  <ul>
                    <li>行为埋点</li>
                    <li>API 接口</li>
                    <li>全域智能营销</li>
                  </ul>
                </div>

                <div className="ksh">
                  <img src={ksh} alt="" />
                  <ul>
                    <li>核心指标</li>
                    <li>转化漏斗</li>
                    <li>业务大屏</li>
                  </ul>
                </div>

                <div className="jw">
                  <img src={jw} alt="" />
                  <ul>
                    <li>CDP</li>
                    <li>VDP</li>
                    <li>MDP</li>
                    <li>NDP</li>
                  </ul>
                </div>
              </Slide>
            </div>
            <Fade left>
              <img src={jt} alt="" className="jt1" />
              <img src={jt} alt="" className="jt2" />
              <img src={jt} alt="" className="jt3" />
              <img src={jt} alt="" className="jt4" />
              <img src={jt} alt="" className="jt5" />
              <img src={jt} alt="" className="jt6" />
              <img src={jt} alt="" className="jt7" />
            </Fade>
          </div>
        )}
      </div>

      <div className="item-3">

        <img src={bg3} alt="" />


        <div className='item-h2'>
          <h2>数智赋能平台</h2>
        </div>
        <ul>
          <li>
            <img src={xk1} alt="" />

            <div className="position">
              <h4>消费者体验智能化</h4>
              <div className="hengxian"></div>
              <div className="list">
                <div className="item">
                  <img src={pp} alt="" /> <span>某汽车品牌</span>
                </div>
                <div className="item">
                  <img src={xfz} alt="" /> <span>消费者</span>
                </div>
                <div className="item">
                  <img src={szhty} alt="" /> <span>数智化体验</span>
                </div>
              </div>
            </div>
          </li>
          <li>

            <img src={xk1} alt="" />

            <div className="position-2">
              <h4>车企内部数智化</h4>
              <div className="hengxian"></div>
              <div className="list">
                <div className="item">
                  <img src={yxzx} alt="" /> <span>营销中心</span>
                </div>
                <div className="item">
                  <img src={mdgj} alt="" /> <span>门店工具</span>
                </div>
                <div className="item">
                  <img src={yxybmd} alt="" /> <span>营销样板门店</span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <img src={xk1} alt="" />

            <div className="position-3">
              <h4>行业生态赋能</h4>
              <div className="hengxian"></div>
              <div className="list">
                <div className="left">
                  <div>
                    <img src={cxgs} alt="" /> <span>车险公司</span>
                  </div>
                  <div>
                    <img src={wxgs} alt="" /> <span>维修公司</span>
                  </div>
                  <div>
                    <img src={escgs} alt="" /> <span>二手车公司</span>
                  </div>
                </div>

                <div className="right">
                  <div>
                    <img src={sjgs} alt="" /> <span>数据公司</span>
                  </div>
                  <div>
                    <img src={sfgs} alt="" /> <span>算法公司</span>
                  </div>
                  <div>
                    <img src={wbgs} alt="" /> <span>外包公司</span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div className="type">
          <Slide bottom>
            <div className="top">
              <div>
                <div className="item">
                  <img src={xk2} alt="" />
                  <div className="text">CDP</div>
                </div>
                <p>客户意向评估与个性推荐、用户画像与再购用户激活</p>
                <p>客户流失预测及挽回营销、客户产品偏好与推广策略</p>
              </div>
              <div>
                <div className="item">
                  <img src={xk2} alt="" />
                  <div className="text">VDP</div>
                </div>
                <p>车辆定价与估值、车辆产品规划、电池健康度</p>
                <p>资产管理、车辆运营数据、车辆驾驶行为</p>
              </div>
              <div>
                <div className="item">
                  <img src={xk2} alt="" />
                  <div className="text">NDP</div>
                </div>
                <p>网络健康度评级、4S/商圈/桩选址、网络数据监测</p>
                <p>在线培训系统</p>
              </div>
            </div>
          </Slide>
          <Slide bottom>
            <div className="buttom">
              <div>
                <div className="item">
                  <img src={xk2} alt="" />
                  <div className="text">MDP</div>
                </div>
                <p>智能推荐、内容生产、内容运营、尊崇交互</p>
                <p>画像标签</p>
              </div>
              <div>
                <div className="item">
                  <img src={xk2} alt="" />
                  <div className="text translate-1">UDDP</div>
                </div>
                <p>数据集成、数据治理、数据资产规划</p>
                <p>数据资产评估、数据资产应用</p>
              </div>
              <div>
                <div className="item">
                  <img src={xk2} alt="" />
                  <div className="text translate-2">UMAAS</div>
                </div>
                <p>AI资产、模型集市、多维建模、模型实时训练</p>
                <p>模型部署、模型监控、模型分析</p>
              </div>
              <div>
                <div className="item">
                  <img src={xk2} alt="" />
                  <div className="text ">MA</div>
                </div>
                <p>营销策略分析、营销策略画布、策略任务工作流</p>
                <p>元数据管理、AB测试、渠道管理、系统管理</p>
              </div>
            </div>
          </Slide>
        </div>
      </div>

      <div className="item-4">
        <img src={bg4} alt="" />
        <div className="position">
          {/* CDP全域营销数据中台 */}
          <div>
            <Slide left>
              <div>
                <a className="data-md" name='CDP'> </a>
                <h4>CDP全域营销数据中台</h4>
                <div>
                  <div className="item">
                    <span>核心模块</span>
                    <span>
                      客户画像标签、营销自动化、产品服务匹配、活动管理、网络选址、销量预测等。
                    </span>
                  </div>
                  <div className="item">
                    <span>产品定位</span>
                    <span>
                      围绕“人-车”生命周期轨迹的全面触点数据（车辆行为、网络行为、消费行为、
                      <br />
                      社交媒体等大规模数据），整合汽车销售主体（主机厂/经销商集团/汽车电商/服
                      <br />
                      务连锁）
                      的内部场景数据与外部行为数据，建立基于行业认知的算法模型，使用
                      <br />
                      大数据技术分析并
                      预测客户行为，获取洞察并指导业务行动，监测跟踪行动结果
                      <br />
                      并进行持续迭代优化。全面
                      挖掘客户价值形成360度标签体系和客户洞察，通过
                      <br />
                      客户标签系统、车主/潜客消费地图、
                      网点选择、产品/价格偏好等，为销售主体
                      <br />
                      提供用户 “选、购、用、养、换、” 全生命周
                      期多业务场景的智能产品推荐、
                      <br />
                      营销 活动组织与客户体验提升。
                    </span>
                  </div>
                  <div className="item">
                    <span>服务对象</span>
                    <span>
                      汽车主机厂、经销商集团、汽车销售连锁、汽车电商公司、保险公司、金融公司等。
                    </span>
                  </div>
                </div>
              </div>
            </Slide>
            <Slide right>
              <div className="right">
                <img src={CDP} alt="" />
              </div>
            </Slide>
          </div>
          {/* VDP车辆数据中台 */}
          <div>
            <Slide left>
              <div className="left">
                <img src={VDP} alt="" />
              </div>
            </Slide>

            <Slide right>
              <div>
                <a className="data-md" name='VDP' > </a>
                <h4>VDP车辆数据中台</h4>
                <div>
                  <div className="item">
                    <span>核心模块</span>
                    <span>
                      车辆数据采集、新能源汽车电池健康度监测、残值动态、残值预测、车辆估值、
                      <br />
                      残值影响因素、残值报告、保值营销等。
                    </span>
                  </div>
                  <div className="item">
                    <span>产品定位</span>
                    <span>
                      针对车辆数据动态变化，为主机厂、保险公司、金融公司、融资租赁公司提供车
                      <br />
                      辆相关精准数据及算法模型，通过对
                      车辆大数据的监测及预测，获取对车辆生产、
                      <br />
                      设计、定价、服务提升、产品升级、残值预测等多场景化的应用，与CDP可形成
                      <br />
                      完整的标签体系和产品及客户洞察。
                    </span>
                  </div>
                  <div className="item">
                    <span>服务对象</span>
                    <span>
                      汽车主机厂、经销商、融资租赁公司、保险公司、金融公司等。
                    </span>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
          {/* NDP数据驱动的智能网络分析决策平台 */}
          <div>
            <Slide left>
              <div>
                <a className="data-md" name='NDP' > </a>
                <h4>NDP数据驱动的智能网络分析决策平台</h4>
                <div>
                  <div className="item">
                    <span>核心模块</span>
                    <span>网络规划、商圈选址、点位推荐、电桩选址等。</span>
                  </div>
                  <div className="item">
                    <span>产品定位</span>
                    <span>
                      针对营销网络动态变化，为主机厂提供整体智慧网络分析决策服务，解决汽车销
                      <br />
                      售主体（主机厂/经销商集团/汽车电商/服务连锁/充电桩）的痛点。众调科技通过
                      <br />
                      政府数据、主机厂网络数据、结合本竞品网点情况数据、地区热力地图、历史上
                      <br />
                      牌分布等，为主机厂制定网点规划策略、商圈选址、充电桩选址、经销商健康度
                      <br />
                      监测等提供大数据智慧赋能。帮助主机厂在网络管理中提升工作能效，同时为网
                      <br />
                      络规划决策提供强有力的数据支撑，确保网络布点的合理性与盈利能力。
                    </span>
                  </div>
                  <div className="item">
                    <span>服务对象</span>
                    <span>
                      汽车主机厂、经销商集团、汽车销售连锁、汽车电商公司、保险公司、金融公司等。
                    </span>
                  </div>
                </div>
              </div>
            </Slide>
            <Slide right>
              <div className="right">
                <img src={NDP} alt="" />
              </div>
            </Slide>
          </div>
          {/* MDP内容数据中台 */}
          <div>
            <Slide left>
              <div className="left">
                <img src={MDP} alt="" />
              </div>
            </Slide>
            <Slide right>
              <div>
                <a className="data-md" name='MDP' > </a>
                <h4>MDP内容数据中台</h4>
                <div>
                  <div className="item">
                    <span>核心模块</span>
                    <span>
                      智能推荐，内容生产，内容运营，尊崇交互，画像标签等。
                    </span>
                  </div>
                  <div className="item">
                    <span>产品定位</span>
                    <span>
                      针对客户以及经销商数据动态变化，为客户分享想要的内容，形成千人千像的营
                      <br />
                      销方案；为品牌方快速传播官方内容，提供品牌故事及营销方案上面的全方位提
                      <br />
                      升，与CDP可形成完整的标签体系和产品及客户洞察。
                    </span>
                  </div>
                  <div className="item">
                    <span>服务对象</span>
                    <span>
                      汽车主机厂、经销商集团、汽车销售连锁、汽车电商公司、保险公司、金融公司等。
                    </span>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
          {/* UDDP */}
          <div>
            <Slide left>
              <div>
                <a className="data-md" name='UDDP' > </a>
                <h4>UDDP数字资产中台</h4>
                <div>
                  <div className="item">
                    <span>核心模块</span>
                    <span>
                      数据集成、数据治理、数据资产规划、数据资产评估、数据资产应用。
                    </span>
                  </div>
                  <div className="item">
                    <span>产品定位</span>
                    <span>
                      众调数据资产管理平台（UnicData Data
                      Platform）整合多源异构数据，通过可 <br />
                      视化一站式的数据治理、数据加工、数据融合、与智能的数据资产规划能力。将最
                      <br />
                      有业务价值的数据通过合理、合规、安全、高效的方式提供给各业务条线的数据使
                      <br />
                      用方，并全方面监控数据资产在全业务流程中赋能程度和成效。
                    </span>
                  </div>
                  <div className="item">
                    <span>服务对象</span>
                    <span>汽车主机厂、经销商集团、汽车电商公司。</span>
                  </div>
                </div>
              </div>
            </Slide>
            <Slide right>
              <div className="right">
                <img src={UDDP} alt="" />
              </div>
            </Slide>
          </div>
          {/* UMAAS算法模型平台 */}
          <div>
            <Slide left>
              <div className="left">
                <img src={UMAAS} alt="" />
              </div>
            </Slide>
            <Slide right>
              <div>
                <a className="data-md" name='UMAAS' > </a>
                <h4>UMAAS算法模型平台</h4>
                <div>
                  <div className="item">
                    <span>核心模块</span>
                    <span>
                      AI资产、模型集市、多维建模、模型实时训练、模型部署、模型监控、模型分析等。
                    </span>
                  </div>
                  <div className="item">
                    <span>产品定位</span>
                    <span>
                      UMaaS（UnicData Model As A
                      Service）平台面向模型开发者和模型使用者，
                      <br />
                      围绕模型创建、模型训练、模型效果分析、模型服务应用等一站式模型管理平台。
                      <br />
                      用户简单操作即可完成模型的创建、部署等功能，改变了以往只有技术人员才能建
                      <br />
                      模的状况，为用户提供了高效能的AI算法建模平台
                    </span>
                  </div>
                  <div className="item">
                    <span>服务对象</span>
                    <span>
                      汽车主机厂、经销商集团、汽车买家、汽车电商公司、保险公司、金融公司等。
                    </span>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
          {/* MA营销平台 */}
          <div>
            <Slide left>
              <div>
                <a className="data-md" name='MA' > </a>
                <h4>MA营销自动化</h4>
                <div>
                  <div className="item">
                    <span>核心模块</span>
                    <span>
                      数据集成、元数据管理、营销策略画布、策略任务工作流、营销策略分析、AB测
                      <br />
                      试、渠道管理、系统管理。
                    </span>
                  </div>
                  <div className="item">
                    <span>产品定位</span>
                    <span>
                      为应对客户获取，客户维护和转化，客户忠诚度渠道数字化和多样化浪潮以及新的
                      <br />
                      媒介类型层出不穷的趋势，营销策略运营平台（MA)通过提供业务领域数据整合
                      <br />
                      能力，并提供元数据管理，以此为基础让营销和运营人员能够根据不同场景，不同
                      <br />
                      的用户画像，实现触达方式和触达内容的千人千面，并且全过程可定制，可监控，
                      <br />
                      可复盘，更重要的是能够借用无代码（No
                      Code)模式实现全自动化的客户旅程的
                      <br />
                      维系和触达，不仅极大降低了业务实现的周期，大大提高了营销和运营人员的效率，
                      <br />
                      更重要的是提高了整体对客户目标达成的效果。
                    </span>
                  </div>
                  <div className="item">
                    <span>服务对象</span>
                    <span>汽车主机厂、经销商集团、汽车电商公司。</span>
                  </div>
                </div>
              </div>
            </Slide>
            <Slide right>
              <div className="right">
                <img src={MA} alt="" />
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataCenter
