import detail1 from '@/assets/detailbg1.png'
import detail2 from '@/assets/detailbg2.png'
import detail3 from '@/assets/detailbg3.png'
import detail4 from '@/assets/detailbg4.png'
import '../index.less'
const ClassFour = () => {
  return (
    <div className="m-detail-item-1">
      <div className="bg-1">
        <img src={detail1} alt="" />
        <div className="text">
          <h2>帮助某豪华品牌DCC项目</h2>
        </div>
      </div>
      <div className="bg-2">
        <img src={detail2} alt="" />
        <div className="text">
          <h3>背景与目标</h3>
          <span>
            汽车消费模式潜移默化的发生转变，“线索”成为各大品
            牌的主战场，也是经销商赖以生存的生命主线，竞争日趋白热
            化的今天，经销商迫于生存压力，节约人力成本，致使人员效
            能饱和，造成“线索”浪费，缺乏有效管理体系。如何在传统
            汽车消费模式下，从网电销板块寻求新生机成为新课题。
          </span>
        </div>
      </div>
      <div className="bg-3">
        <img src={detail3} alt="" />
        <div className="text">
          <h3>痛点及挑战</h3>
          <ul className='tong-item'>
            <li>
              数字化转型面临两大断层，总部的运营理念和数字工具难以向下传递
            </li>
            <li>
              随着数字化转型在车企的不断深入应用，行业面临两大断层，制约品牌ToC能力的打造，阻碍用户服务
              的落地，难以实现达成用户运营的目标，
              亟需总部和MAC赋能经销商加快转型。
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-4">
        <img src={detail4} alt="" />
        <div className="text">
          <h3>解决方案</h3>
          <span>
          从问题溯源、标准制定、培训赋能、数据分析等方面来开展工
          作。评估了经销商DCC运营状况，制定了《DCC运营手册》，开展
          了线上培训，VCT视频、论坛等多钟形式的线上线下辅导培训；定期分析DCC数据全面提升经销商DCC能力。
          </span>
        </div>
      </div>
    </div>
  )
}

export default ClassFour
