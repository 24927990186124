import './index.less'

import React, { Component } from 'react'
import { Col, Row } from 'antd'
import introduction from '@/assets/ecologypeitu_3.png'
import CoState1 from '@/assets/IMG_6943.png'
import CoState from '@/assets/NewUi.png'
import CoState2 from '@/assets/ecologypeitu_2.png'
import readMoreIcon from '@/assets//icon_more.png'
import peitu4 from '@/assets/peitu_4.png'
import peitu5 from '@/assets/peitu_5.png'
import peitu6 from '@/assets/peitu_6.png'
import peitu7 from '@/assets/ecologypeitu_7.png'
import teamImg1 from '@/assets/ecologypeitu_8.png'
import teamImg2 from '@/assets/ecologypeitu_9.png'
import teamImg3 from '@/assets/ecologypeitu_10.png'
import teamImg4 from '@/assets/ecologypeitu_11.png'
import teamImg5 from '@/assets/zj-5.jpg'
import teamImg6 from '@/assets/zj-6.jpg'

import Bounce from 'react-reveal/Bounce'

import Fade from 'react-reveal/Fade'
export default class Ecology extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
      repartIndex: 0,
      repIndex: '',
    }
    this.setCurrentIndex = this.setCurrentIndex.bind(this)
    this.setRepartIndex = this.setRepartIndex.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  setCurrentIndex(event) {
    this.setState({
      currentIndex: parseInt(event.currentTarget.getAttribute('index'), 10),
    })
  }
  openUrl(i) {
    if (i == 0) {
      window.open('https://mp.weixin.qq.com/s/FoZNON9Hixeda9XcnCFjZA')
    } else if (i == 1) {
      window.open('https://mp.weixin.qq.com/s/syyPopojbxAPbsAvqHovAw')
    } else if (i == 2) {
      window.open('https://mp.weixin.qq.com/s/OhpuYjMC5eGiBwqmgTD49g')
    } else if (i == 3) {
      window.open('https://mp.weixin.qq.com/s/Kr9_r0YM8r2Wc79zeB_Q9A')
    } else if (i == 4) {
      window.open('https://mp.weixin.qq.com/s/nRc88PUv1NvETCQieODsrA')
    } else if (i == 5) {
      window.open('https://mp.weixin.qq.com/s/IAlsnugAIo5hL6e1UmTSgQ')
    }
  }
  setRepartIndex(i, event) {
    this.setState({
      repartIndex: parseInt(event.currentTarget.getAttribute('index'), 10),
    })

    this.openUrl(i)
  }
  handleMouseEnter(i, event) {
    this.setState({
      repartIndex: parseInt(event.currentTarget.getAttribute('index'), 10),
    })
  }
  render() {
    let categoryArr = [
      '公司简介',
      '公司动态',
      '研究报告',
      '专家团队',
      '众调荣誉',
    ]
    let repartArr = [
      '2022-5月分析报告',
      '2022-4月分析报告',
      '2022-1月分析报告',
      '2021-12月分析报告',
      '2021-11月分析报告',
      '2021-10月分析报告',
    ]
    let itemList = []
    let repartList = []

    for (let i = 0; i < categoryArr.length; i++) {
      itemList.push(
        <li
          key={i}
          className={this.state.currentIndex === i ? 'checkedThatLi' : ''}
          index={i}
          onClick={this.setCurrentIndex}
        >
          <label htmlFor={'aa' + i}>{categoryArr[i]}</label>
        </li>
      )
    }
    for (let i = 0; i < repartArr.length; i++) {
      repartList.push(
        <Fade left>
          <li
            key={i}
            className={this.state.repartIndex === i ? 'checkRepartContent' : ''}
            index={i}
            onMouseEnter={(e) => this.handleMouseEnter(i, e)}
            onClick={(e) => this.setRepartIndex(i, e)}
          >
            {repartArr[i]}
          </li>
        </Fade>
      )
    }
    return (
      <div className="home">
        <div className="banner">
          <div className="banner-content">
            <div>
              <span className="banner-title">
                <Fade bottom>公司生态</Fade>
              </span>
              <span className="banner-subtitle"></span>
              <span className="banner-des">
                <Fade bottom>数字中台驱动BaaS创领者</Fade>
              </span>
            </div>
          </div>
        </div>
        <div className="introduction">
          <div className="navCoDiv">
            <ul className="category">{itemList}</ul>
          </div>
          <div className="ecology-title-div" name="aa0">
            公司<span>简介</span>
          </div>
          <div className="ecology-content-div">
            <Row className="ecology-content-div-row">
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Fade left big>
                  <div className="ecology-content-div-row-left">
                    <img src={introduction} alt="/" />
                  </div>
                </Fade>
              </Col>
              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <Fade right big>
                  <div
                    className="ecology-content-div-row-right"
                    style={{ padding: '30px' }}
                  >
                    <span>
                      众调科技成立于2015年5月25日，定位于以“一个大脑，两个引擎”为核心的大数据智能引擎服务商，基于中台驱动的营销大脑和产品+成交双引擎，从数据、产品、服务等三个层面展开深度融合，为车企数字化企业提供一体化智慧运营解决方案，帮助车企打通线上线下、链接前台后台、贯通私域公域。同时众调科技也为零售、保险、地产等行业提供咨询和数智化解决方案，现有业务包含数据中台、业务中台、AI中台、成交引擎、直售引擎等。
                    </span>
                    <span style={{ marginTop: '20px' }}>
                      目前，众调科技已为国内外半数车企提供服务，在销售、出行、金融等多方面为企业赋能。同时，众调科技在人工智能
                      +
                      数据技术应用方面也沉淀了行业领先的丰富实践经验，并将此成功经验复制至其他行业领域进行应用。经过多年在大数据行业的深耕，已成为上海市科技小巨人培育企业，荣获高新技术及双软企业、专精特精企业，同时加入阿里云服务生态伙伴。
                    </span>
                  </div>
                </Fade>
              </Col>
            </Row>
          </div>
        </div>
        <div className="stata">
          <div className="ecology-title-div">
            公司<span>动态</span>
          </div>
          <input
            type="text"
            name="SocSecNum"
            className="hiddenInput"
            id="aa1"
          />
          <div className="ecology-content-div" style={{ height: '400px' }}>
            <Row
              className="ecology-content-div-row"
              style={{ height: '100%', position: 'relative' }}
            >
              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  zIndex: '999',
                }}
              >
                <Fade left bottom big>
                  <div className="ecology-content-div-row-left">
                    <img src={CoState} alt="/" />
                  </div>
                </Fade>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={20}
                lg={20}
                xl={20}
                style={{
                  position: 'absolute',
                  right: '0',
                  bottom: '-10%',
                }}
              >
                <Fade right top big>
                  <div
                    className="ecology-content-div-row-right "
                    style={{ height: '100%' }}
                  >
                    <img src={CoState2} style={{ width: '100%' }} alt="" />
                    <div className="CoStateContent">
                      <div>
                        <span
                          style={{
                            color: '#1255ED',
                            fontWeight: '600',
                            fontFamily: 'Source Han Sans CN',
                          }}
                        >
                          众调汽车大数据
                        </span>
                        <h2
                          style={{
                            color: '#333',
                            fontWeight: '800',
                            padding: '10px 0',
                          }}
                        >
                          众调科技连获两轮近亿元融资，打造人车全生命周期数智赋能平台
                        </h2>
                        <span>
                        众调科技连续获两轮近亿元战略融资，此次连续两轮融资第一轮由“博康共赢”领投，
                        嘉定创投旗下天使基金联合跟投，第二轮由国科投资独投。公司新一轮融资已经启动，由拾钰资本担任独家财务顾问。
                          {/* 引导企业走“专精特新”发展之路，是党中央、国务院促进中小企业高质量发展的一项重要举措，也是科技强国战略的重要组成部分。
                          近日，经上海市经济和信息化委员会等部门严格评审，凭借深厚专业技术和持续创新能力等突出表现， */}
                        </span>
                        <div
                          className="readMore"
                          onClick={() => {
                            window.open(
                              'https://mp.weixin.qq.com/s/QIjwK6J6uxALzmHp4bMU4A'
                            )
                          }}
                        >
                          READ MORE
                          <img
                            src={readMoreIcon}
                            style={{ width: '5%', marginLeft: '12px' }}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Col>
            </Row>
          </div>

          <div className="stata-card-div">
            <Row
              className="stateCardDiv"
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
               <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                onClick={() => {
                  window.open(
                    'https://mp.weixin.qq.com/s/23M5DwfQaqsXE9tGDLFRNA'
                  )
                }}
              >
                <Bounce bottom>
                  <div>
                    <img src={peitu6} alt="" />
                  </div>

                  <div className="stateCardDiv-content">
                    {/* <span
                      className="stateCardDiv-content-span"
                      style={{
                        color: '#1255ED',
                        fontWeight: '600',
                        fontFamily: 'Source Han Sans CN',
                      }}
                    >
                      众调汽车大数据
                    </span> */}
                    <h2
                      style={{
                        color: '#333333',
                        fontWeight: '800',
                        padding: '10px 0',
                      }}
                    >众调科技荣膺阿里云&ensp;2023项目攻坚奖…
                    </h2>
                    <span>
                    作为阿里云生态战略合作伙伴，上海众调信息科技有限公司以大数据为基础，以人工智能为引擎，在汽车垂直行业的营销科技、车联智能、自动驾驶等领域布局，构建了“PaaS +MaaS +SaaS”的产品矩阵…{' '}
                    </span>
                  </div>
                </Bounce>
              </Col>

               <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                onClick={() => {
                  window.open(
                    'https://mp.weixin.qq.com/s/XeGug1cflOesxrvb1jKAYQ'
                  )
                }}
              >
                <Fade bottom big>
                  <div>
                    <img src={CoState1} alt="" />
                  </div>
                  <div className="stateCardDiv-content">
                    {/* <span
                      className="stateCardDiv-content-span"
                      style={{
                        color: '#1255ED',
                        fontWeight: '600',
                        fontFamily: 'Source Han Sans CN',
                      }}
                    >
                      众调汽车大数据
                    </span> */}
                    <h2
                      style={{
                        color: '#333333',
                        fontWeight: '800',
                        padding: '10px 0',
                      }}
                    >
                      喜报|众调科技荣获上海市&ensp;专精特新&ensp;企业
                    </h2>
                    <span>
                      引导企业走“专精特新”发展之路，是党中央、国务院促进中小企业高质量发展的一项重要举措，也是科技强国战略的重要组成部分。
                      近日，经上海市经济和信息化委员会等部门严格评审，凭借深厚专业技术和持续创新能力等突出表现...{' '}
                    </span>
                  </div>
                </Fade>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={8}
                lg={8}
                xl={8}
                onClick={() => {
                  window.open('https://www.sohu.com/a/139329112_501677')
                }}
              >
                <Fade bottom>
                  <div>
                    <img src={peitu4} alt="" />
                  </div>

                  <div className="stateCardDiv-content">
                    {/* <span
                      className="stateCardDiv-content-span"
                      style={{
                        color: '#1255ED',
                        fontWeight: '600',
                        fontFamily: 'Source Han Sans CN',
                      }}
                    >
                      众调汽车大数据
                    </span> */}
                    <h2
                      style={{
                        color: '#333333',
                        fontWeight: '800',
                        padding: '10px 0',
                      }}
                    >
                      众调科技强强联手普华永道…
                    </h2>
                    <span>
                      近日，普华永道（中国）与众调科技正式签署战略合作协议。本次战略合作针对双方在汽车行业的资源优势与创新能力达成共识，共同进行后续的市场活动、产品和服务的研发、行业指数研发及推广等各项事项...{' '}
                    </span>
                  </div>
                </Fade>
              </Col>

             
            </Row>
          </div>
        </div>

        <div className="repart">
          <div className="ecology-title-div">
            研究<span>报告</span>{' '}
            <span
              style={{
                color: '#1255ED',
                fontWeight: '600',
                fontFamily: 'Source Han Sans CN',
                marginLeft: '20px',
                fontSize: '14px',
                borderBottom: 'none',
              }}
            >
              众调汽车大数据
            </span>
          </div>
          <input
            type="text"
            name="SocSecNum"
            className="hiddenInput"
            id="aa2"
          />

          <div className="repartContent">
            <Row>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <ul className="repartContentList">
                  {repartList}
                  {/* <Fade left> 
                                        <li className="checkRepartContent">2022-5分析报告</li>
                                      </Fade>  
                                      <Fade left> 
                                        <li>2022-4分析报告</li>
                                      </Fade>  
                                      <Fade left> 
                                        <li>2022-1分析报告</li>
                                      </Fade> 
                                      <Fade left> 
                                        <li>2021-12分析报告</li>
                                      </Fade>  
                                        <Fade left> 
                                        <li>2022-11分析报告</li>
                                       </Fade>  
                                        <Fade left> 
                                        <li>2022-10分析报告</li>
                                        </Fade> */}
                </ul>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={14}
                lg={14}
                xl={14}
                className="repartContentImg"
              >
                <Fade right big>
                  <img src={peitu7} alt="" />
                </Fade>
              </Col>
            </Row>
          </div>
        </div>
        <div className="team">
          <div className="ecology-title-divt">
            专家<span>团队</span>
          </div>
          <div className="teamRow">
            <input
              type="text"
              name="SocSecNum"
              className="hiddenInput"
              id="aa3"
            />
            <Fade bottom big>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="teamRowImg">
                    <img src={teamImg3} alt="" />
                  </div>
                  <div className="teamRowInfo">
                    <div className="teamRowInfo-name">
                      叶明海 <span>教授/博导</span>
                    </div>
                    <div className="teamRowInfo-middleDiv">
                      <div>同济大学MBA教学中心主任</div>
                    </div>

                    <div className="teamRowInfo-div">
                      中国著名汽车营销专家 / 旅德访问学者 / 众调科技首席顾问 /上海科技创业中心创业导师
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="teamRowImg">
                    <img className='teamImg6' src={teamImg6} alt="" />
                  </div>
                  <div className="teamRowInfo">
                    <div className="teamRowInfo-name"> 凌鸿</div>

                    <div className="teamRowInfo-middleDiv">
                      <div>复旦大学管理学院教授，信息管理系主任</div>
                    </div>

                    <div className="teamRowInfo-div">
                      美国麻省理工学院访问学者，信息系统协会中国分会理事会理事。上海集优机械股份有限公司独立董事。
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="teamRowImg">
                    <img src={teamImg1} alt="" />
                  </div>
                  <div className="teamRowInfo">
                    <div className="teamRowInfo-name">
                      郑鑫 <span>众调科技董事长</span>
                    </div>
                    <div className="teamRowInfo-middleDiv">
                      <div>同济大学 博士 教授</div>
                      <div>美国南加州大学 访问学者</div>
                      <div>汽车营销和大数据专家</div>
                    </div>

                    <div className="teamRowInfo-div">
                      同济大学-IBM大数据技术及分析中心执行主任，同济大学-享道出行移动大数据人工智能实验室执行主任，中关村大数据产业联盟汽车专委会主任，20年汽车产业经验，连续创业者。
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="teamRowImg">
                    <img src={teamImg4} alt="" />
                  </div>
                  <div className="teamRowInfo">
                    <div className="teamRowInfo-name"> 赵国栋</div>

                    <div className="teamRowInfo-middleDiv">
                      <div> 中关村大数据产业联盟秘书长</div>
                    </div>

                    <div className="teamRowInfo-div">
                      中国计算机学会大数据专家委员会委员 /
                      北京邮电大学经济管理学院MBA特聘导师 / 盘古智库学术
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="teamRowImg">
                    <img className='teamImg5' src={teamImg5} alt="" />
                  </div>
                  <div className="teamRowInfo">
                    <div className="teamRowInfo-name">
                      林倞
                    </div>
                    <div className="teamRowInfo-middleDiv">
                      <div>中山大学数据科学与计算机学院教授</div>
                    </div>

                    <div className="teamRowInfo-div">
                      国家万人计划青年拔尖人才，国家优秀青年基金获得者，教育部超算工程软件工程研究中心副主任，IET Fellow。商汤科技执行研发总监/研究院副院长/杰出科学家。
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className="teamRowImg">
                    <img src={teamImg2} alt="" />
                  </div>
                  <div className="teamRowInfo">
                    <div className="teamRowInfo-name">
                      谢鹏 <span>众调科技CEO</span>
                    </div>
                    <div className="teamRowInfo-middleDiv">
                      <div>中关村大数据产业联盟汽车专委会秘书长</div>
                    </div>

                    <div className="teamRowInfo-div">
                      复旦MBA，16年互联网及创新管理经验，曾就职多家国内外知名企业，具有丰富的互联网/大数据技术与管理经验
                    </div>
                  </div>
                </Col>
              </Row>
            </Fade>
          </div>
        </div>
        <div className="honor">
          <div className="ecology-title-div" style={{ height: '18vw' }}>
            众调<span>荣誉</span>
          </div>
          <input
            type="text"
            name="SocSecNum"
            className="hiddenInput"
            id="aa4"
          />
          <div className="honorList">
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Fade bottom big>
                  <ul>
                    <li>· 上海市高新技术企业</li>
                    <li>· 上海市科技小巨人孵化企业</li>
                    <li>· 汽车大数据专业委员会主任单位</li>
                    <li>· 戴姆勒Star Autobahn最佳精准奖</li>
                    <li>· 信息服务业新业态创新企业30强</li>
                    <li>· 中国大数据行业应用Top Choice</li>
                  </ul>
                </Fade>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Fade bottom big>
                  <ul>
                    <li>· 上海市专精特新企业</li>
                    <li>· 双软企业</li>
                    <li>· 邓白氏注册认证企业</li>
                    <li>· 中关村大数据产业联盟优秀大数据企业</li>
                    <li>· 中国汽车互联网+创新100强</li>
                    <li>· 十佳数字化转型解决方案项目</li>
                  </ul>
                </Fade>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }
}
