import detail1 from '@/assets/detailbg1.png'
import detail2 from '@/assets/detailbg2.png'
import detail3 from '@/assets/detailbg3.png'
import detail4 from '@/assets/detailbg4.png'
import './class.less'
const ClassTwo = () => {
  return (
    <div className="m-detail-item-1">
      <div className="bg-1">
        <img src={detail1} alt="" />
        <div className="text">
          <h2>豪华品牌二手车业务</h2>
        </div>
      </div>
      <div className="bg-2">
        <img src={detail2} alt="" />
        <div className="text">
          <h3>背景与目标</h3>
          <span>
            知名车企想要从数据层面解决企业烟囱式业务系统的数据成为新车销量的第一主导因素；因此，没有二手车和处置能力，会对品牌残值和新车销售造成重大不利影响。
          </span>
        </div>
      </div>
      <div className="bg-3">
        <img src={detail3} alt="" />
        <div className="text">
          <h3>痛点及挑战</h3>
          <ul className='tong-item'>
            <li>
              主机厂开展二手车业务面临两大关键问题：
              对经销商二手车业务掌控能力弱和经销商意愿不高；
            </li>
            <li>
              对应八大痛点，分别是缺车源、缺政策、缺体系、缺赋能、缺盈利、缺管理、缺能力和缺客流。
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-4">
        <img src={detail4} alt="" />
        <div className="text">
          <h3>解决方案</h3>
          <span>
          从车源、认证、营销、零/批售、衍生支持等维度，制定品牌认证
          二手车业务的发展策略。建立牌二手车运营指导手册，全面指导经销商
          认证二手车业务。对认证二手车系统进行升级优化，赋能经销商快速建
          立认证二手车业务能力。管理工具赋能，帮助厂家和经销商获取客户估
          值行为、置换线索、二手车零售线索等 网络建设优化：经销商招募和验
          收，累计招募 132 家，授权留存 109 家培训辅导：二手车业务课程开
          发及培训辅导
          </span>
        </div>
      </div>
    </div>
  )
}

export default ClassTwo
