import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router'
import ClassOne from './components/class-one'
import ClassTwo from './components/class-two'
import ClassThree from './components/class-three'
import ClassFour from './components/class-four'
import ClassFive from './components/class-five'
import ClassSix from './components/class-six'

const CaseDetail = () => {
  const location = useLocation()
  const cutCompoents = useCallback(() => {
    const id = location.state.id
    if (id === 0) {
      return <ClassOne />
    } else if (id === 1) {
      return <ClassTwo />
    } else if (id === 2) {
      return <ClassThree />
    } else if (id === 3) {
      return <ClassFour />
    } else if (id === 4) {
      return <ClassFive />
    } else if (id === 5) {
      return <ClassSix />
    }
  }, [location])

  useEffect(() => {
    window.scrollTo(0, 0)

    cutCompoents()
  }, [location, cutCompoents])

  return (
    <div className="case-detail">
      {cutCompoents()}
    </div>
  )
}

export default CaseDetail
