import detail1 from '../../../../assets/bg_1detail.jpg'
import detail2 from '../../../../assets/bg_2detail.jpg'
import detail3 from '../../../../assets/bg_3detail.jpg'
import detail4 from '../../../../assets/bg_4detail.jpg'
import detail5 from '../../../../assets/bg_5detail.jpg'
import yuandian from '../../../../assets/圆点.png'
const ClassFive = () => {
  return (
    <div className="detail-item-1">
      <div className="bg-1">
        <img src={detail1} alt="" />
        <div className="text">
          <h2>帮助某一线品牌打造客户</h2>
          <h2>数据平台和自动化营销平台项目</h2>
        </div>
      </div>
      <div className="bg-2">
        <img src={detail2} alt="" />
        <div className="text">
          <h3>背景与目标</h3>

          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
            品牌面临数字化转型的关键阶段，数字化赋能蓄势待发，
          </p>
          <p>通过本项目将构建客户数据平台和自动化营销平台，实现业</p>
          <p>务赋能。</p>
        </div>
      </div>
      {/* <div className="bg-3">
        <img src={detail3} alt="" />
        <div className="text">
          <h3>痛点及挑战</h3>

          <ul>
            <li>
              <img src={yuandian} alt="" />
              为了满足业务快速发展的需求，企业构建了太多烟囱式应用。应
              用间信息孤岛严重，数据难以流通。应用 <br />
              间能力难以复用，资源浪费严重；
            </li>
            <li>
              <img src={yuandian} alt="" />
              缺少统一的业务系统集成途径，数据格式、协议多样化，难以传输和集成；
              数据质量不高，难以高效利用。
            </li>
            <li>
              <img src={yuandian} alt="" />
              管理不规范：数据模型管理缺乏规范化体系，大数据平台内数据的技术、业务属性不清晰，没有完善的数据模
              <br />
              型管理体系与规范。
            </li>
            <li>
              <img src={yuandian} alt="" />
              缺少与合作伙伴分享数据和业务能力服务的便捷途径；
            </li>
          </ul>
        </div>
      </div> */}
      <div className="bg-4">
        <img src={detail4} alt="" />
        <div className="text">
          <h3>解决方案</h3>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
            品牌汽车客户数据平台和自动化营销平台基于品牌Newbie
          </p>
          <p>和Data Lake, 助力品牌构建业务与数据双中台驱动数字化转型基</p>
          <p>于品牌现有系统与数据平台，打造数据与数字驱动的CDP与MA</p>
          <p>平台，构建品牌特色的标签与指标体系，实现360客户画像与全，</p>
          <p>渠道智能交互通过产品与数据智能实现全链路数智赋能与效果跟</p>
          <p>踪提升研发并实践算法驱动的基于场景的数字化产品与服务，步</p>
          <p>骤实现品牌车主的客户体验提升与销售服务的营销效果转化 </p>
        </div>
      </div>
      {/* <div className="bg-5">
        <img src={detail5} alt="" />
        <div className="text">
          <h3>产品优势</h3>
          <p>
            大数据管理平台能够实现数据的全生命周期管理，帮助各角色用户快速识别各业务系统的数据，并进行统一管理，最后将标
            准的、高质量、 高准确性的数据提供给业务人员和前台业务系统，
            <br />
            它具备以下优势：
          </p>
          <ul>
            <li>
              <img src={yuandian} alt="" />
              全域数据资产采集、清洗和整合
            </li>
            <li>
              <img src={yuandian} alt="" />
              实现数据从创建到消亡全生命周期的可视化管理
            </li>
            <li>
              <img src={yuandian} alt="" />
              完善的数据资产审核流程，全面保障纳入平台中数据资产的准确性、权威性
            </li>
            <li>
              <img src={yuandian} alt="" />
              数据资产管理流程可自定义，满足各类审批场景
            </li>
            <li>
              <img src={yuandian} alt="" />
              丰富的智能元素和功能，大大缩短数据管理周期、减少成本浪费
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  )
}

export default ClassFive
