import React, { useCallback, useEffect } from 'react'
import bg_1 from '../../assets/bg_1.png'
import bg_2 from '../../assets/bg_2.png'
import pic_1 from '../../assets/pic_1.png'
import pic_2 from '../../assets/pic_2.png'
import pic_3 from '../../assets/pic_3.png'
import pic_4 from '../../assets/pic_4.png'
import pic_5 from '../../assets/pic_5.png'
import pic_6 from '../../assets/pic_6.png'
import more from '../../assets/more.png'
import Slide from 'react-reveal/Fade'
import './index.less'
import { useNavigate } from 'react-router-dom'

const Case = () => {
  const n = useNavigate()
  const jumpDetail = useCallback(
    (id) => {
      n('/case-detail', { state: { id } })
    },
    [n]
  )
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="case">
      <div className="banner-1">
        <img src={bg_1} alt="" className="big-img" />
        <div className="text">
          <Slide bottom>
            <h2>成功案例</h2>
          </Slide>
          <Slide bottom>
            <h2 className="mini-h2">
              众调科技已为众多行业客户提供全域智能解决方案
            </h2>
          </Slide>
        </div>
      </div>

      <div className="banner-2">
        <img src={bg_2} alt="" />

        <div className="app-list">
          <div className="app-item">
            <div className="top">
              <img src={pic_1} alt="" />
            </div>

            <div className="buttom">
              <h4>车联移动数据管理与应用平平平平平平平</h4>
              <p>
                某知名车企想要从数据层面解决企业烟囱式业务系统的数据同步、采集、传输、加工、采集、传输、加工采集、传输、加工采集、传输、加工
              </p>
              <div className="footer">
                <span></span>

                <div className="botton" onClick={() => jumpDetail(0)}>
                  <div>了解详情</div>
                  <img src={more} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="app-item">
            <div className="top">
              <img src={pic_5} alt="" />
            </div>

            <div className="buttom">
              <h4>客户数据平台和自动化营销...</h4>
              <p>
                品牌面临数字化转型的关键阶段，数字化赋能蓄势待发，通过本项目将构建客户数据平...
              </p>
              <div className="footer">
                <span></span>
                <div className="botton" onClick={() => jumpDetail(4)}>
                  <div>了解详情</div>
                  <img src={more} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="app-item">
            <div className="top">
              <img src={pic_6} alt="" />
            </div>

            <div className="buttom">
              <h4>豪华品牌内容中台项...</h4>
              <p>
                在互联网大数据洪流下，用户运营从流量获取到转化的模式已掀起潮流，这股浪潮使客...
              </p>
              <div className="footer">
                <span></span>
                <div className="botton" onClick={() => jumpDetail(5)}>
                  <div>了解详情</div>
                  <img src={more} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="app-item">
            <div className="top">
              <img src={pic_4} alt="" />
            </div>

            <div className="buttom">
              <h4>豪华品牌DCC项目</h4>
              <p>
                汽车消费模式潜移默化的发生转变，“线索”成为各大品牌的主战场，也是经销商赖...
              </p>
              <div className="footer">
                <span></span>
                <div className="botton" onClick={() => jumpDetail(3)}>
                  <div>了解详情</div>
                  <img src={more} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="app-item">
            <div className="top">
              <img src={pic_3} alt="" />
            </div>

            <div className="buttom">
              <h4>某德系品牌打造直售代理模...</h4>
              <p>
                近年来，越来越多的汽车品牌选择代理商销售模式。由于经销模式转换是一个过程...
              </p>
              <div className="footer">
                <span></span>
                <div className="botton" onClick={() => jumpDetail(2)}>
                  <div>了解详情</div>
                  <img src={more} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="app-item">
            <div className="top">
              <img src={pic_2} alt="" />
            </div>

            <div className="buttom">
              <h4>提高豪华品牌二手车业务</h4>
              <p>
                随着汽车消费市场的成熟，增换购占比逐年快速增长，成为新车销量的第一主导因素...
              </p>
              <div className="footer">
                <span></span>
                <div className="botton" onClick={() => jumpDetail(1)}>
                  <div>了解详情</div>
                  <img src={more} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Case
